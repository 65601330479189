type GetRamblerIdHelper = {
  (): Promise<any>
  _loading?: Promise<unknown>
}

const getRamblerIdHelper: GetRamblerIdHelper = async () => {
  if (!window.ramblerIdHelper || Array.isArray(window.ramblerIdHelper)) {
    if (!getRamblerIdHelper._loading) {
      const script = document.createElement('script')

      script.type = 'text/javascript'
      script.src = 'https://id.rambler.ru/rambler-id-helper/auth_events.js'
      script.async = true
      getRamblerIdHelper._loading = new Promise((resolve, reject) => {
        script.onreadystatechange = resolve
        script.onload = resolve
        script.onerror = reject
      })
      document.head?.appendChild(script)
    }

    await getRamblerIdHelper._loading
  }

  return window.ramblerIdHelper
}

export const getRamblerIdProfileInfo = async (
  params?: Record<string, any>
): Promise<any> => {
  const rih = await getRamblerIdHelper()

  return new Promise((resolve) => {
    rih.getProfileInfo(params, (data) => resolve(data))
  })
}

export const openRamblerIdLogin = async (): Promise<void> => {
  const rih = await getRamblerIdHelper()

  rih.redirectToAuth({
    rname: 'vp',
    registration_link: 'https://id.rambler.ru/login-20/registration'
  })
}

export const getSessionToken = async (): Promise<void> => {
  const rih = await getRamblerIdHelper()

  return new Promise((resolve, reject) => {
    rih.getSessionToken((data, error) => {
      if (error) reject(error)
      resolve(data)
    })
  })
}

export const logout = async (): Promise<void> => {
  const rih = await getRamblerIdHelper()

  return new Promise((resolve, reject) => {
    rih.logout((data, error) => {
      if (error) reject(error)
      resolve()
    })
  })
}

export const addListener = async (...args: any): Promise<void> => {
  const rih = await getRamblerIdHelper()

  rih.addListener(...args)
}

export const removeListener = async (...args: any): Promise<void> => {
  const rih = await getRamblerIdHelper()

  rih.removeListener(...args)
}
