import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  createContext
} from 'react'
import classnames from 'classnames'
import styles from './styles.css'

type ContextType = {
  isResponsive: boolean
  setResponsive: (arg0: boolean) => void
}

const Context = createContext<ContextType>({
  isResponsive: false,
  setResponsive: () => {} // eslint-disable-line
})

type PageProps = {
  aside?: React.ReactNode
  children: React.ReactNode
  className?: string
  redesigned?: boolean
  forwardRef?: React.RefObject<HTMLElement>
}

const Page: React.FC<PageProps> = ({
  aside,
  children,
  className,
  redesigned,
  forwardRef
}) => {
  const [isResponsive, setResponsive] = useState(false)

  const context = useMemo(
    () => ({
      isResponsive,
      setResponsive
    }),
    [isResponsive]
  )

  return (
    <Context.Provider value={context}>
      <main
        className={classnames(
          className,
          styles.root,
          isResponsive && styles.responsive,
          redesigned && 'redesigned'
        )}
        ref={forwardRef}>
        {aside && <aside className={styles.aside}>{aside}</aside>}
        <div className={styles.content}>{children}</div>
      </main>
    </Context.Provider>
  )
}

export default Page

export const useResponsiveContent = (): void => {
  const context = useContext(Context)

  useEffect(() => {
    context.setResponsive(true)

    return () => {
      context.setResponsive(false)
    }
  }, [])
}

export const ResponsivePageActivator: React.FC = () => {
  useResponsiveContent()

  return null
}
