// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ee2go {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  padding: 20px calc((100% - 265px) / 2);\n  text-align: center;\n  background: var(--white);\n}\n\n.BYQFb {\n  flex: none;\n  margin: 0 0 15px;\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 25px;\n}\n\n.KsGDX {\n  flex: none;\n  line-height: 20px;\n}\n\n.CNiVb {\n  flex: none;\n  margin: 20px 0 0;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PageMessage/styles.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,sCAAsC;EACtC,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".root {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  padding: 20px calc((100% - 265px) / 2);\n  text-align: center;\n  background: var(--white);\n}\n\n.heading {\n  flex: none;\n  margin: 0 0 15px;\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 25px;\n}\n\n.content {\n  flex: none;\n  line-height: 20px;\n}\n\n.control {\n  flex: none;\n  margin: 20px 0 0;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Ee2go",
	"heading": "BYQFb",
	"content": "KsGDX",
	"control": "CNiVb"
};
export default ___CSS_LOADER_EXPORT___;
