import React, {useCallback} from 'react'
import {Popup} from 'rambler-ui/Popup'
import Button from 'rambler-ui/Button'
import LearningCtr from 'components/images/LearningCtr'
import styles from './styles.css'

const TG_CHANNEL_URL = process.env.TG_CHANNEL_URL

type Props = {
  onSuccess: () => any
  onClose: () => any
}

const WelcomePopup: React.FC<Props> = ({onClose, onSuccess, ...props}) => {
  const goToChannel = useCallback(() => {
    window.open(TG_CHANNEL_URL)
    onSuccess()
  }, [])

  return (
    <Popup
      {...props}
      className={styles.root}
      okButton={
        <Button className={styles.button} onClick={goToChannel}>
          Подписаться
        </Button>
      }
      cancelButton={
        <Button className={styles.button} type="outline" onClick={onClose}>
          Не сейчас
        </Button>
      }>
      <LearningCtr className={styles.image} />

      <header className={styles.heading}>
        Привет!
        <br />
        Добро пожаловать на&nbsp;Видеоплатформу!
      </header>

      <p className={styles.message}>
        {'Подпишитесь на наш телеграм-канал '}
        <a href={TG_CHANNEL_URL} target="_blank" rel="noreferrer">
          Видеоплатформа Rambler&Co
        </a>
        {', чтобы узнавать актуальные новости сервиса'}
      </p>
    </Popup>
  )
}

export default WelcomePopup
