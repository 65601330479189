import React from 'react'
import {
  useLocation,
  type Location,
  useNavigate,
  type NavigateFunction,
  useParams,
  type Params
} from 'react-router-dom'
import getDisplayName from 'rambler-ui/utils/get-display-name'

export type WithRouterProps = {
  location: Location
  navigate: NavigateFunction
  params: Params
}

export default function <Config>(
  Target: React.ComponentType<Config>
): React.FC<Omit<Config, keyof WithRouterProps>> {
  const WithRouter: React.FC<Omit<Config, keyof WithRouterProps>> = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    return (
      <Target
        {...(props as Config)}
        location={location}
        navigate={navigate}
        params={params}
      />
    )
  }

  WithRouter.displayName = `withRouter(${getDisplayName(Target)})`

  return WithRouter
}
