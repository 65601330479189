import {createContext} from 'react'
import EventEmitter from 'eventemitter3'
import {snackbarEvent} from 'components/rc/snackbar'
import type {Permissions, ProcessedAccountPermissions} from 'types/Permission'
import type {AccountAvailableSection} from 'types/Account'
import type {GetConfirmation} from 'components/ConfirmationProvider'

export const DEFAULT_PERMISSIONS: {
  accountId: null
  accountName: null
  accountCode: string
  permissions: Permissions
  availableSections: AccountAvailableSection[]
} = {
  accountId: null,
  accountName: null,
  accountCode: '',
  permissions: {
    yauth: {},
    streaming: {},
    media: {}
  },
  availableSections: []
}

type User = {
  id: number | null
  admin: boolean
  email: string | null
  avatar?: string
  confirmationNeeded?: true
}

export const DEFAULT_USER: User = {
  id: null,
  admin: false,
  email: null
}

export type State = {
  isFetching: boolean
  permissions: Record<string, ProcessedAccountPermissions> | null
  copyrightHolderPermissions: string[] | null
  accountsList: string[]
  accountCodes: Record<number, string>
  user: User | null
  accountCode: string | null
}

export type SnackbarProps = Partial<
  Pick<
    Parameters<typeof snackbarEvent>[0],
    'type' | 'align' | 'autoCloseDuration' | 'withCloseButton' | 'multiline'
  >
> & {
  message: string
  actionButton?: string
  onAction?: () => any
}

export type ContextType = State & {
  user: User
  permissions: State['permissions']
  copyrightHolderPermissions: State['copyrightHolderPermissions']
  openSnackbar: (arg0: SnackbarProps) => void
  handleException: (error: Error) => void
  getConfirmation: GetConfirmation
  copyValue: (value: string | number, msg: string) => Promise<void>
  emitter: EventEmitter
  getAccountPermissions: () =>
    | typeof DEFAULT_PERMISSIONS
    | ProcessedAccountPermissions
  getPath: (path: string, account?: string) => string
}

export const DEFAULT_STATE = {
  isFetching: false,
  permissions: null,
  copyrightHolderPermissions: null,
  accountsList: [],
  accountCodes: {},
  user: null,
  accountCode: null
}

const DEFAULT_CONTEXT = {
  ...DEFAULT_STATE,
  user: DEFAULT_USER,
  permissions: {},
  copyrightHolderPermissions: [],
  openSnackbar: () => {}, // eslint-disable-line
  handleException: () => {}, // eslint-disable-line
  getConfirmation: () => Promise.reject(),
  copyValue: () => Promise.reject(),
  emitter: new EventEmitter(),
  getAccountPermissions: () => DEFAULT_PERMISSIONS,
  getPath: (path: string) => `/${path}`
}

export default createContext<ContextType>(DEFAULT_CONTEXT)
