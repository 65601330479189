import {api} from './api'

export type FrontSettings = Record<
  string,
  boolean | string | number | null | undefined
>

export type MetadataTypeValue =
  | null
  | boolean
  | number
  | string
  | number[]
  | string[]

export type RecordUploadingState =
  | 'UPLOADING'
  | 'COMPLETING'
  | 'CONVERTING'
  | 'REPLICATING'
  | 'READY'
  | 'ERROR'

export type RecordFilesItem = {
  bitrate: number
  height: number
  id: number
  name: string
  resourceType: string
  size: number
  status: string
  width: number
}

export type RecordResult = {
  adTemplateId: null | number
  ageRestrictionsType: null | number
  albumIds: null | number[]
  clickUrl: null | string
  convertingProgressExpectedDuration: null
  convertingProgressMicrosLeft: null
  convertingProgressProcessedMicros: null
  convertingProgressUpdatedAt: null | string // 2024-05-02T18:50:04.775827
  countryAccessTemplateId: null | number
  createdAt: string // 2024-05-02T18:50:04.775827
  currentPreviewId: null | number
  currentScreenshot: null | string
  currentScreenshotSmall: null | string
  currentState: null | RecordUploadingState
  currentStateErrorMessage: string
  currentStateUpdatedAt: null | string // 2024-05-02T18:50:04.775827
  deletedAt: null | string // 2024-05-02T18:50:04.775827
  description: null | string
  duration: number
  frontSettings: null | FrontSettings
  id: number
  isHd: null | boolean
  isMute: null | boolean
  isProcessed: boolean
  isReuploading: boolean
  metadataList: {
    metadataType: string
    value: MetadataTypeValue
  }[]
  name: string
  originSize: number
  originalFilename: string
  parentAccountName: null | string
  parentRecordId: null | number
  percent: null | number
  playerTemplateId: null | number
  publicationPeriod: null | number
  publishedFrom: null | string // 2024-05-02T18:50:04.775827
  publishedUntil: null | string // 2024-05-02T18:50:04.775827
  recordFiles: RecordFilesItem[]
  recordedAt: null | string // 2024-05-02T18:50:04.775827
  screenshotId: number
  screenshots: string[]
  sharedAt: null | string // 2024-05-02T18:50:04.775827
  sharedRecords: {
    id: number
    accountId: number
  }[]
  siteAccessTemplateId: null | number
  subtitles: string[]
  tags: string[]
  updatedAt: string // 2024-05-02T18:50:04.775827
  useEncryptor: boolean
  userEmail: string
  userId: number
  uuid: string
  views: number
  youtubeId: null | string
  uploadToYoutube: boolean
}

export const getRecord = (
  payload: {
    id: number
    accountCode: string
  },
  signal?: AbortSignal | null
): Promise<{
  result: RecordResult
}> => api('/api/v3/records/get', payload, {signal})

export type UpdateRecordPayload = RecordResult & {
  newCustomScreenshot?: string
  removeCustomScreenshot?: boolean
}

export const updateRecord = (
  payload: UpdateRecordPayload,
  signal?: AbortSignal | null
): Promise<{
  result: Omit<RecordResult, 'recordFiles' | 'sharedRecords'> & {
    recordFiles: null
    sharedRecords: null
  }
}> => api('/api/v3/records/update', payload, {signal})

// В `metadataList` передаются только редактируемые поля, если значение нужно сбросить, поле `value` в `metadataList` должно быть `null`
export type UpdateRecordsPayload = Partial<
  Pick<
    RecordResult,
    | 'clickUrl'
    | 'description'
    | 'useEncryptor'
    | 'adTemplateId' // `-1` если нужно установить значение по умолчанию
    | 'playerTemplateId' // `-1` если нужно установить значение по умолчанию
    | 'siteAccessTemplateId' // `-1` если нужно установить значение по умолчанию
    | 'countryAccessTemplateId' // `-1` если нужно установить значение по умолчанию
    | 'ageRestrictionsType'
    | 'publishedFrom'
    | 'publishedUntil'
    | 'publicationPeriod'
    | 'tags'
    | 'albumIds'
    | 'metadataList'
  >
>

type UpdateRecordsStates = Record<number, boolean>

export const updateRecords = (
  payload: UpdateRecordsPayload & {
    accountCode: string
    ids: number[] // массив id видеозаписей (минимум 1, максимум 50)
  },
  signal?: AbortSignal | null
): Promise<{
  result: {
    updateStates: UpdateRecordsStates
  }
}> => api('/api/v3/records/updateMulti', payload, {signal})

export const updateRecordsByPart = async (
  payload: Parameters<typeof updateRecords>[0],
  signal?: AbortSignal | null,
  onPartUpdated?: (ids: number[]) => any,
  partSize = 50
): Promise<UpdateRecordsStates> => {
  const ids = [...payload.ids]
  const resultPartSize = Math.max(1, Math.min(partSize, 50))
  let output: UpdateRecordsStates = {}

  while (ids.length) {
    const resultIds = ids.splice(0, resultPartSize)
    const {
      result: {updateStates}
    } = await updateRecords(
      {
        ...payload,
        ids: resultIds
      },
      signal
    )

    onPartUpdated?.(resultIds)
    output = {...output, ...updateStates}
  }

  return output
}

export type RecordsResultItem = Omit<RecordResult, 'parentAccountName'>

export const getRecordsListByFilter = (
  payload: {
    accountCode: string
    filterId: number
    albumIds?: number[]
    page?: number // по-умолчанию 0
    pageLimit?: number // по-умолчанию 50
    orderField?: string // по-умолчанию createdAt
    orderDir?: 'asc' | 'desc' // по-умолчанию desc
  },
  signal?: AbortSignal | null
): Promise<{
  result: {
    totalPages: number
    totalRecords: number
    records: RecordsResultItem[]
  }
}> => api('/api/v3/records/getListByFilter', payload, {signal})

export type SearchRecordsResultItem = Pick<
  RecordResult,
  | 'id'
  | 'uuid'
  | 'userId'
  | 'userEmail'
  | 'name'
  | 'description'
  | 'clickUrl'
  | 'currentScreenshot'
  | 'adTemplateId'
  | 'playerTemplateId'
  | 'tags'
  | 'albumIds'
  | 'duration'
  | 'originSize'
  | 'isProcessed'
  | 'views'
  | 'sharedAt'
  | 'currentState'
  | 'createdAt'
  | 'updatedAt'
  | 'deletedAt'
> & {
  hasSharedRecords: boolean
  metadata: Record<string, MetadataTypeValue>
}

export const getSearchRecords = (
  payload: {
    accountCode: string
    searchText: string
    useSpellcheck?: boolean
    tags?: string[]
    copyrightHolderNames?: string[]
    themes?: string[]
    categories?: string[]
    albumIds?: number[]
    playerTemplateIds?: number[]
    adTemplateIds?: number[]
    from: number
    size: number
    sort: string
  },
  signal?: AbortSignal | null
): Promise<{
  result: {
    records: SearchRecordsResultItem[]
    recordsFound: number
    totalRecordsFound: number
    spelled: string | null
  }
}> => api('/api/v3/search/getRecords', payload, {signal})

export type RecordsInfoItem = Pick<
  RecordResult,
  'id' | 'name' | 'userEmail'
> & {
  accountCode: string
}

// Возвращает данные по не удаленным записям
export const getRecordsInfo = (
  recordIds: number[],
  signal?: AbortSignal
): Promise<{
  result: {
    records: RecordsInfoItem[]
  }
}> =>
  api(
    '/api/v3/records/info',
    {
      recordIds
    },
    {
      signal
    }
  )

export const getRecordsInfoByPart = async (
  recordIds: number[],
  signal?: AbortSignal
): Promise<RecordsInfoItem[]> => {
  const records: RecordsInfoItem[] = []
  const partSize = 200
  let start = 0

  while (start < recordIds.length) {
    const end = start + partSize

    const {result} = await getRecordsInfo(recordIds.slice(start, end), signal)

    records.push(...result.records)
    start = end
  }

  return records
}

type SharedRecordsResult = Record<string, Record<number, number>>

export const shareRecords = (
  payload: {
    ids: number[] // массив id видеозаписей (минимум 1, максимум 50)
    targetAccountCodes: string[] // массив кодов аккаунтов, в которые надо пошарить записи (минимум 1, максимум 10)
    copyrightHolder?: string // правообладатель (source_name)
    theme?: string // тематика (puid5)
    category?: string // категория (puid6)
    albumId?: number // id альбома (по-умолчанию используется альбом с названием аккаунта)
  },
  signal?: AbortSignal | null
): Promise<{
  result: {
    sharedRecords: SharedRecordsResult
  }
}> =>
  api('/api/v3/records/share', payload, {
    signal
  })

export const shareRecordsByPart = async (
  payload: Parameters<typeof shareRecords>[0],
  signal?: AbortSignal | null
): Promise<SharedRecordsResult> => {
  const targetAccountCodes = [...payload.targetAccountCodes]
  const output: SharedRecordsResult = {}

  while (targetAccountCodes.length) {
    const targetAccountCodesResult = targetAccountCodes.splice(0, 10)
    const ids = [...payload.ids]

    while (ids.length) {
      const idsResult = ids.splice(0, 50)
      const response = await shareRecords(
        {
          ...payload,
          targetAccountCodes: targetAccountCodesResult,
          ids: idsResult
        },
        signal
      )

      Object.entries(response.result.sharedRecords).forEach(
        ([accountCode, recordsMap]) => {
          Object.entries(recordsMap).forEach(([key, value]) => {
            output[accountCode] = {
              ...output[accountCode],
              [key]: value
            }
          })
        }
      )
    }
  }

  return output
}

export const unshareRecords = (
  payload: {
    ids: number[] // массив id видеозаписей (минимум 1, максимум 50)
    targetAccountCodes: string[] // массив кодов аккаунтов, в которые надо пошарить записи (минимум 1, максимум 10)
  },
  signal?: AbortSignal | null
): Promise<void> =>
  api('/api/v3/records/unshare', payload, {
    signal
  })

export const unshareRecordsByPart: typeof unshareRecords = async (
  payload,
  signal
) => {
  const targetAccountCodes = [...payload.targetAccountCodes]

  while (targetAccountCodes.length) {
    const targetAccountCodesResult = targetAccountCodes.splice(0, 10)
    const ids = [...payload.ids]

    while (ids.length) {
      const idsResult = ids.splice(0, 50)

      await unshareRecords(
        {
          ...payload,
          targetAccountCodes: targetAccountCodesResult,
          ids: idsResult
        },
        signal
      )
    }
  }
}

export const uploadToYoutube = async (
  recordId: number,
  signal?: AbortSignal | null
): Promise<void> =>
  api('/api/v3/records/uploadToYoutube', {id: recordId}, {signal})

export const detachFromYoutube = async (
  recordId: number,
  signal?: AbortSignal | null
): Promise<void> =>
  api('/api/v3/records/detachFromYoutube', {id: recordId}, {signal})

export const getPreviewPlayerData = (
  payload: {
    account: string
    id: number
    referrer: string
  },
  signal?: AbortSignal
): Promise<{
  result: {
    playList: {
      directSource: string
      source: string
    }
  }
}> =>
  api('/api/v3/records/previewPlayerData', payload, {
    signal,
    useSessionId: true
  })

export const getCountNewRecords = (
  payload: {
    account: string
    albums: {
      id: number
      time: Date
    }[]
  },
  signal?: AbortSignal
): Promise<{
  result: {
    idToCount: {
      id: number
      count: number
    }[]
  }
}> =>
  api('/api/v3/records/getCountNewRecords', payload, {
    signal
  })

type RecordReferrerStatistic = {
  recordId: number
  fullReferrerCallCount: Record<string, number>
  shortReferrerCallCount: Record<string, number>
  domainReferrerCallCount: Record<string, number>
}

export const getRecordReferrerStatistic = (
  recordsIds: number[],
  signal?: AbortSignal
): Promise<{
  result: {
    recordReferrerStatistic: RecordReferrerStatistic[]
  }
}> => api('/api/v3/records/getRecordReferrerStatistic', {recordsIds}, {signal})

type AiAdItem = {
  begin: number
  end: number
  product: {
    currency: string
    id: number
    name: string
    pic: string
    price: number
    url: string
    vendor: string
  }
}

export type AiAdConfig = {
  optimized: AiAdItem[]
  original: AiAdItem[]
}

export const getAiAds = (
  recordId: number,
  signal?: AbortSignal | null
): Promise<{
  result: AiAdConfig
}> =>
  api(
    '/api/v3/records/getAiAds',
    {cid: recordId},
    {
      signal
    }
  )
