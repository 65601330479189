import React from 'react'

const LearningCtr: React.FC<
  Omit<React.ComponentPropsWithRef<'svg'>, 'width' | 'height' | 'children'>
> = (props) => (
  <svg {...props} width="350" height="250">
    <defs>
      <path
        id="learningCtr1"
        d="M.98 5.38V174.3a4.65 4.65 0 0 0 3.51 4.51l206.63 34.25a4.65 4.65 0 0 0 5.77-4.51V37.81a4.65 4.65 0 0 0-3.92-4.58L6.34.82a4.64 4.64 0 0 0-3.73 1.05A4.65 4.65 0 0 0 .98 5.38z"
      />
      <path
        id="learningCtr2"
        d="m104.67 69.83-103-17.38A2 2 0 0 1 0 50.48V2A2 2 0 0 1 2.31.02l103 16.44a2 2 0 0 1 1.69 1.98v49.42a2 2 0 0 1-2.33 1.97z"
      />
      <path
        id="learningCtr3"
        d="M30.55 5.32c1.08 1.25.84 4.26 2.4 6.18 1.13 1.48 4.17 3.68 4.27 4.09.1.41-1.64 1.85-2 2.36-.36.51 1.31 9.41-.46 10.86-2.84 2.13-7.65 3.13-8.46 4.75-.81 1.62-4.07 9.9 1.11 14.16 3.1.86 2.92-3.46 15.45 2.76 2.48 2.75 3.7 3.57 6.59 10.67 3.09 7.58 12.56 11.21 17.4 12.61C68.6 77.23 68.44 90 65.38 90S41.68 78.85 32.47 67.5c-8-9.9-31.9-14-31.9-14s5.5-36.55 6-38C7.07 14.05 18.04.92 18.04.92s10.34 1.88 12.51 4.4z"
      />
      <path
        id="learningCtr4"
        d="M9.37 24.77s2.6 14.68 1.24 21.7c-.75 3.87-.57 8.75-5.37 19.36-6.33 10.8-6.33 23.34 0 30.47 7.33 8.29 10.27 10.46 15.1 20.79 2.2 4.34 2.62 6.99 3.92 11.68 1.69 5.43 1.7 4.67 3.11 8.99 11 2.03 23.17 2.57 23.18 2.57-.06-12.27-5.37-34.06-6.71-47.96a49.25 49.25 0 0 1 4.63-35c2.13-3.76 9.32-15.72 9.11-19.16-3.75-2.75-3.51-5.66-3.8-8.66-.91-9.4 2.79-17.33 5-17-3.2-3.06-14.25-6.83-18.26-8.41-7 .92-14.34-1.36-18.87-3.91-8.42-1.12-9.32 2.69-9.32 2.69L9.37 24.77z"
      />
      <path
        id="learningCtr5"
        d="m65.68 53.9-64-10.43A2 2 0 0 1 0 41.49V2A2 2 0 0 1 2.32.03l64 10.52A2 2 0 0 1 68 12.52v39.4a2 2 0 0 1-2.32 1.98z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#EAEBEE"
        fillRule="nonzero"
        d="M9.13 117.08c2-22.5 21-113 119.5-102 76.21 8.51 107.76 54.8 129 61.5 23.53 7.44 68.92 26.92 79 51 18 43-31.21 112-130.5 117-69 3.5-206.42-21.8-197-127.5z"
      />
      <g stroke="#FFF" strokeWidth="3" transform="rotate(-9 905.3 -1604.84)">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m.66 8.28 19.5-7.16a8 8 0 0 1 5.47-.02l26.98 9.73h0"
        />
        <ellipse cx="54.5" cy="10" fill="#EAEBEE" rx="4.5" ry="7" />
      </g>
      <g stroke="#FFF" strokeWidth="3" transform="translate(25 117)">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m8.47 41.48 34.05 6.57L40.99.98"
        />
        <ellipse cx="5.41" cy="40.86" fill="#EAEBEE" rx="4.5" ry="7" />
      </g>
      <g transform="translate(57 15)">
        <mask id="learningCtr6" fill="#fff">
          <use xlinkHref="#learningCtr1" />
        </mask>
        <use xlinkHref="#learningCtr1" fill="#BED3FC" fillRule="nonzero" />
        <ellipse
          cx="20"
          cy="10.5"
          fill="#D8D8D8"
          mask="url(#learningCtr6)"
          rx="3"
          ry="4.5"
        />
        <path
          fill="#78A7FF"
          fillRule="nonzero"
          d="m-4.32 13.87 232.87 36.11V16.22L-6.66-8.9z"
          mask="url(#learningCtr6)"
          opacity=".63"
        />
      </g>
      <path
        fill="#FFF"
        d="M79.5 26.65c-.3 2.41-1.84 3.89-3.45 3.3-1.59-.58-2.64-2.98-2.35-5.37.3-2.4 1.82-3.88 3.42-3.32 1.6.57 2.67 2.98 2.38 5.39M70 24.11c0 2.43-1.35 4.08-3.01 3.7-1.66-.38-2.99-2.65-2.99-5.05 0-2.41 1.33-4.07 2.99-3.71 1.66.36 3.01 2.63 3.01 5.06"
      />
      <use
        xlinkHref="#learningCtr2"
        fill="#424D63"
        fillRule="nonzero"
        transform="translate(236 133)"
      />
      <ellipse
        cx="26.05"
        cy="32.79"
        fill="#5080FF"
        rx="24.88"
        ry="31.25"
        transform="rotate(-12 434.38 -836.66)"
      />
      <path
        fill="#92B6FD"
        d="m19.98 99.07 114.96 18.97.55 36.52L19 134.94a3 3 0 0 1-2.5-2.96v-29.95a3 3 0 0 1 3.5-2.96z"
      />
      <path
        fill="#5080FF"
        d="m19.98 99.07 30.84 5.11v36.18L19 134.94a3 3 0 0 1-2.5-2.96v-29.95a3 3 0 0 1 3.5-2.96z"
      />
      <path stroke="#EAEBEE" strokeWidth="3" d="M50.43 99.46v45.64" />
      <path
        stroke="#E2E4E9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="m25.1 117.49 5.3 9.5 9.29-14.66"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="m145.68 106.36-69-11.25A2 2 0 0 1 75 93.13V44a2 2 0 0 1 2.32-1.97l69 11.34a2 2 0 0 1 1.68 1.98v49.04a2 2 0 0 1-2.32 1.97zm-24.42 8.22c-.46.41-11.93 11.77-15.8 18.62a14.56 14.56 0 0 0-1.84 6c-.64 7.53-2.23 26-2.87 29.18-.8 3.95-5.71 8.27-5.92 12.25-.21 3.98.82 4.75 1.43 5.07.51.27 2.11-1.07 4.8-4.02-1.73 3.04-2.55 4.73-2.46 5.09.14.53.53.62.95.42 1.46-.1 7.78-10.98 8.72-9.1.94 1.88 2.7 8.64 3.27 5.58.57-3.06-1.41-8.83-.9-11 .5-2.17 5.23-29.14 5.87-29.78.95-.94 11.36-12.73 11.36-12.73l-6.61-15.58z"
      />
      <path
        fill="#426FE2"
        fillRule="nonzero"
        d="M104.83 231.94c2.96-9.27 6.69-12.76 9.97-22.37a12.86 12.86 0 0 0 .6-5.24c-1.45-17.16.6-24.95 4.57-29.35 3.59-4 19.97 37.85 19.97 37.85s-7.97 20.48-13.36 24.7c-7.73-1.47-16.32-4.41-21.75-5.6z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M192 142.2c0-3.76 3.16-8.32 5.51-12.86 2.35-4.54 3.13-12.31 3.13-12.31a36.47 36.47 0 0 1-2.08-8.51c-.21-3.54.9-6.44 1.48-4.35.36 1.3 1.06 4 1.49 4.15a.79.79 0 0 0 .78-.55c.7-1.16 1.88-10 3.37-9.76 2 .36-.46 8.36 1 8 .88-.56.48-7.53 2.57-6.95 2.09.58 4.15 2.13 4.37 4 .35 5.34-1.42 10.64-3.29 16.09.07 3.83-4.13 25.17-6.67 31.59-3.64 9.09-10.31 2.79-10.31 2.79L192 142.2z"
      />
      <g transform="translate(131 65)">
        <mask id="learningCtr7" fill="#fff">
          <use xlinkHref="#learningCtr3" />
        </mask>
        <use xlinkHref="#learningCtr3" fill="#FFF" fillRule="nonzero" />
        <path
          fill="#E4F0FF"
          d="M23.35 31.27c1.63-1.85 8.16-1.85 12.83-2.61 4.68-.76-6.85 3.15-9.24 5.87-2.4 2.72-1.53 5.99-1.96 5.33-.44-.65-3.26-6.74-1.63-8.59z"
          mask="url(#learningCtr7)"
        />
      </g>
      <g transform="translate(115 103)">
        <mask id="learningCtr8" fill="#fff">
          <use xlinkHref="#learningCtr4" />
        </mask>
        <use xlinkHref="#learningCtr4" fill="#5080FF" fillRule="nonzero" />
        <path
          fill="#426FE2"
          d="M56.24 15.3c-4.19 3.73-6.84 8.8-7.94 15.2-1.65 9.59-6.52 15.07-8.9 22.1-2.36 7.03-13.8 8.43-14.84 12.41-1.04 3.98 8.52 5.9 8.52 14.83 0 8.94-5.3 17.2 0 24.16 5.3 6.95 7.77 14.23 7.77 23.16 0 8.94 5.13 22.84 5.13 22.84s16.88-2.32 16.88-4.64c0-1.54-4.85-25.04-14.56-70.48a3312.87 3312.87 0 0 0 3.61-7.18c7.3-14.56 10.95-22.31 10.95-23.27 0-1.1-2.2-10.8-6.62-29.12z"
          mask="url(#learningCtr8)"
        />
      </g>
      <path
        fill="#FFF"
        d="m245.42 144.17 30.73 4.52a1 1 0 0 1 .85.99v32.6a1 1 0 0 1-1.18.98l-30.73-5.46a1 1 0 0 1-.82-.98v-31.66a1 1 0 0 1 1.15-1z"
      />
      <g transform="translate(79 48)">
        <mask id="learningCtr9" fill="#fff">
          <use xlinkHref="#learningCtr5" />
        </mask>
        <use xlinkHref="#learningCtr5" fill="#FFF" fillRule="nonzero" />
        <path
          fill="#CACED5"
          d="m-1.32 34.99-3.95-10.71 24.9-11.71 12.59 11.7 15-9.57L77.9 41.13l-3.65 22.42-77.94-10.2z"
          mask="url(#learningCtr9)"
        />
        <path
          fill="#5080FF"
          d="M-6.5 37.91 23.69 25l8.28 7.51 26.5-17.45 18.77 20.76v23.33l-50.15-3.48-33.6-10.8z"
          mask="url(#learningCtr9)"
        />
      </g>
      <path
        fill="#424D63"
        fillRule="nonzero"
        d="M139.62 66.19c10.7-8.2 12.2-1.9 18.07-1.6 5.3.26 7.09 4.93 4.26 8.6s-6.24 5-6.5 8.13c-.15 1.83-.92 5.19-2.1 3.36-1.16-1.83-4.57-1.66-4.4.67.17 2.33 1.45 4 1.48 5.8-.81 1.67-.44 2-2.26 6.23-2.6 6.07 2 12.82 1.06 21.35-.56 5.34-7 11.79-9.81 16.88-3.39 6.14-9.28 9.8-16.44 6.43-7.15-3.36-4.41-11.31-6.9-17.74-4.33-11.15-1.82-16 6.9-26.12 6.37-7.43 4.2-9.44 4.1-15.61 0-.93.07-1.85.22-2.76 1.69-10.52 8.32-10.56 12.32-13.62z"
      />
      <path
        fill="#FFF"
        d="m291.48 152.94 43.04 7.49c.86.14 1.48.89 1.48 1.75a1.26 1.26 0 0 1-1.48 1.25l-43.04-7.5a1.78 1.78 0 0 1-1.48-1.75 1.26 1.26 0 0 1 1.48-1.24zm0 14 43.04 7.49c.86.14 1.48.89 1.48 1.75a1.26 1.26 0 0 1-1.48 1.25l-43.04-7.5a1.78 1.78 0 0 1-1.48-1.75 1.26 1.26 0 0 1 1.48-1.24zm0 14 24.3 4.34a1.8 1.8 0 0 1 1.48 1.77 1.26 1.26 0 0 1-1.48 1.23l-24.3-4.33a1.8 1.8 0 0 1-1.48-1.77 1.26 1.26 0 0 1 1.48-1.23z"
      />
      <path
        fill="#5080FF"
        fillRule="nonzero"
        d="m241.63 212.72-57.82-9.74c-8.03-.54-14.33-9.15-13.78-17.7v-.25c.55-8.2 7.65-12.52 15.69-11.98l56.53 9.3a14.2 14.2 0 0 1 10.04 5.12 16.07 16.07 0 0 1 3.69 11.18v.2c-.52 8.53-6.32 14.36-14.35 13.87z"
      />
    </g>
  </svg>
)

export default LearningCtr
