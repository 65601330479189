import React from 'react'
import Spinner from 'rambler-ui/Spinner'
import Page from './'

const PageFallback: React.FC = () => (
  <Page>
    <Spinner />
  </Page>
)

export default PageFallback
