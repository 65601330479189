import {isString} from 'utils/checkType'
import ApiError from 'utils/ApiError'
import {getSessionToken} from 'utils/ramblerIdHelper'
import {captureException} from 'utils/sentry'
import {apiErrorCounter} from 'utils/prometheus'

const NOOP = () => {} // eslint-disable-line

export const config = {
  auth: '',
  media: '',
  messenger: '',
  streaming: '',
  csrfToken: ''
}

export const api = async (
  endpoint: string,
  params?: Record<string, any> | null | undefined,
  method = 'GET',
  options?: RequestInit
): Promise<any> => {
  let body
  const headers = {}
  let search

  const metricsEndpoint = endpoint
    // eslint-disable-next-line sonar/anchor-precedence
    .replace(/https?:\/\/(\S+)(.com|.ru)|\.json$/g, '')
    .replace(/\/\d+\b/g, '')

  if (!endpoint.endsWith('/api/auth/account_by_host'))
    params = {...params, session_id: (await getSessionToken()).token}

  if (params)
    if (method === 'GET' || (options && options.body)) {
      const paramsString = Object.entries(params)
        .map((entry) => entry.join('='))
        .join('&')
      if (paramsString)
        search = `${endpoint.includes('?') ? '&' : '?'}${paramsString}`
    } else {
      if (method === 'PUT' || method === 'POST' || method === 'DELETE')
        headers['Content-Type'] = 'application/json'
      body = JSON.stringify(params)
    }

  if (
    config.csrfToken &&
    (endpoint.includes('/api/v2/') || endpoint.includes('/backbone/'))
  )
    headers['X-CSRF-Token'] = config.csrfToken
  const response = await fetch(`${endpoint}${search || ''}`, {
    body,
    method,
    headers,
    credentials: 'omit',
    ...options
  }).catch((error) => {
    if (error.name !== 'AbortError') {
      apiErrorCounter.inc(1, {
        endpoint: metricsEndpoint,
        errorCode: error.code != null ? String(error.code) : 'unknown'
      })
      captureException(error, {
        params
      })
      throw new ApiError(0, 'request failed', {error})
    }

    throw error
  })

  const json = await response.json().catch(NOOP)

  if (json) {
    let errors: void | string[]

    if (Array.isArray(json.error)) errors = json.error
    else if (json.errors)
      if (Array.isArray(json.errors)) {
        errors = json.errors
      } else {
        const keys = Object.keys(json.errors)
        if (keys.length > 0 && Array.isArray(json.errors[keys[0]]))
          errors = json.errors[keys[0]]
      }

    if (errors && isString(errors[0])) {
      const error = new ApiError(json.status, errors[0])

      apiErrorCounter.inc(1, {
        endpoint: metricsEndpoint,
        errorCode: error.code != null ? String(error.code) : 'unknown'
      })

      captureException(error, {
        params
      })
      throw error
    }
  }

  if (!response.ok) {
    const error = new ApiError(
      response.status,
      response.statusText || 'api error'
    )

    apiErrorCounter.inc(1, {
      endpoint: metricsEndpoint,
      errorCode: error.code != null ? String(error.code) : 'unknown'
    })

    captureException(error, {
      params
    })
    throw error
  }

  if (json && endpoint.endsWith('/me') && json.csrf_token)
    config.csrfToken = json.csrf_token

  if (json && endpoint.endsWith('/api/auth/account_by_host')) {
    const {account} = json.data

    config.auth = `https://${account.host}`
    account.auth_hosts.forEach((item) => {
      const protocol = item.name === 'messenger' ? 'wss' : 'https'

      config[item.name] = `${protocol}://${item.host}`
    })
  }

  return json
}
