import {isObject} from 'utils/checkType'
const LOCAL_STORAGE_KEY = 'vpadmin:accountsVisits'

export const getAccountsVisits = (): Record<string, number> => {
  try {
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '')

    return isObject(data) ? data : {}
  } catch (err: any) {
    return {}
  }
}

export const saveAccountVisit = (accountCode: string): void => {
  const currentData = getAccountsVisits()

  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({
      ...currentData,
      [accountCode]: (currentData[accountCode] ?? 0) + 1
    })
  )
}
