// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sl0Bu.sl0Bu {\n    margin: 20px var(--content-side-padding) calc(20px - var(--rows-gap));\n    -moz-column-count: 3;\n         column-count: 3;\n  }\n", "",{"version":3,"sources":["webpack://./src/containers/AccountsPermissions/styles.css"],"names":[],"mappings":"AACE;IACE,qEAAqE;IACrE,oBAAe;SAAf,eAAe;EACjB","sourcesContent":[".list {\n  && {\n    margin: 20px var(--content-side-padding) calc(20px - var(--rows-gap));\n    column-count: 3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "sl0Bu"
};
export default ___CSS_LOADER_EXPORT___;
