import React from 'react'
import SvgIcon from 'rambler-ui/SvgIcon'

const RedoIcon: React.FC<
  Omit<React.ComponentProps<typeof SvgIcon>, 'children' | 'viewBox'>
> = (props) => (
  <SvgIcon color={null} size={null} {...props} viewBox="0 0 15 15">
    <path d="M1 7.18a6.5 6.5 0 0 0 5.96 6.8.5.5 0 0 0 .54-.5v-.2a.5.5 0 0 0-.46-.5 5.3 5.3 0 0 1-4.82-5.7c.2-2.6 2.3-4.69 4.91-4.87a5.24 5.24 0 0 1 4.56 2.1l-1.26 1.26a.25.25 0 0 0 .17.43h2.9a.5.5 0 0 0 .5-.5V2.6a.25.25 0 0 0-.43-.17l-1 1a6.48 6.48 0 0 0-5.42-2.42A6.52 6.52 0 0 0 1 7.18M15 0v15M0 15V0" />
  </SvgIcon>
)

export default RedoIcon
