import React, {Suspense, lazy} from 'react'
import PageFallback from 'components/Page/Fallback'
import {Props} from './'

const CopyrightHoldersDashboardStat = lazy(
  () =>
    import(
      /* webpackChunkName: "CopyrightHoldersDashboardStat" */
      './'
    )
)

const LazyCopyrightHoldersDashboardStat: React.FC<Props> = (props) => (
  <Suspense fallback={<PageFallback />}>
    <CopyrightHoldersDashboardStat {...props} />
  </Suspense>
)

export default LazyCopyrightHoldersDashboardStat
