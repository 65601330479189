import React from 'react'
import SvgIcon from 'rambler-ui/SvgIcon'

const CloseIcon: React.FC<
  Omit<React.ComponentProps<typeof SvgIcon>, 'children' | 'viewBox'>
> = (props) => (
  <SvgIcon color={null} size={null} {...props} viewBox="0 0 15 15">
    <path d="M0 15V0v15zM15 0v15V0zM8.35 7.5l5.22 5.22a.5.5 0 0 1 0 .7l-.14.15a.5.5 0 0 1-.7 0L7.5 8.35l-5.22 5.22a.5.5 0 0 1-.7 0l-.15-.14a.5.5 0 0 1 0-.7L6.65 7.5 1.43 2.28a.5.5 0 0 1 0-.7l.14-.15a.5.5 0 0 1 .7 0L7.5 6.65l5.22-5.22a.5.5 0 0 1 .7 0l.15.14a.5.5 0 0 1 0 .7L8.35 7.5z" />
  </SvgIcon>
)

export default CloseIcon
