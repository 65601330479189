import React, {cloneElement} from 'react'
import classnames from 'classnames'
import Button from 'rambler-ui/Button'
import PageContent from 'components/PageContent'
import styles from './styles.css'

type Props = {
  className?: string
  heading: string
  message: React.ReactNode
  icon: React.ReactElement<() => React.ReactElement<'svg'>>
  control?: React.ReactElement<typeof Button> | null | undefined
}

const ContentMessage: React.FC<Props> = ({
  className,
  heading,
  message,
  icon,
  control
}) => (
  <PageContent className={classnames(className, styles.container)}>
    {icon}
    <h1 className={styles.heading}>{heading}</h1>
    <div className={styles.message}>{message}</div>
    {control &&
      cloneElement(control, {
        className: styles.control
      })}
  </PageContent>
)

export default ContentMessage
