import React from 'react'
import Loader from 'components/rc/loader'
import Page from './'
import styles from './styles.css'

const PageFallback: React.FC = () => (
  <Page className={styles.fallbackPage}>
    <Loader size="small" className={styles.loader} />
  </Page>
)

export default PageFallback
