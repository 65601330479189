// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X6SrT {\n  display: flex;\n  min-height: 500px;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-family: var(--rui-alt-font-family);\n  text-align: center;\n}\n\n.FgMe0 {\n  margin: 30px 0 15px;\n  font-size: 40px;\n  font-weight: 500;\n  line-height: 1.13;\n}\n\n.ocVi5 {\n  font-size: 14px;\n  line-height: 1.57;\n}\n\n.vX1F6 {\n  margin-top: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContentMessage/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  min-height: 500px;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-family: var(--rui-alt-font-family);\n  text-align: center;\n}\n\n.heading {\n  margin: 30px 0 15px;\n  font-size: 40px;\n  font-weight: 500;\n  line-height: 1.13;\n}\n\n.message {\n  font-size: 14px;\n  line-height: 1.57;\n}\n\n.control {\n  margin-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "X6SrT",
	"heading": "FgMe0",
	"message": "ocVi5",
	"control": "vX1F6"
};
export default ___CSS_LOADER_EXPORT___;
