import React from 'react'
import classnames from 'classnames'
import styles from './styles.css'

type Props = {
  className?: string
  statusClassName?: string
  value: number
  max: number
  children: React.ReactNode
  completed?: boolean
  failed?: boolean
  active?: boolean
}

const ProgressBar: React.FC<Props> = ({
  className,
  statusClassName,
  value,
  max,
  children,
  completed,
  failed,
  active = true
}) => (
  <div
    className={classnames(
      className,
      styles.progressBar,
      active && styles.active,
      completed && styles.completed,
      failed && styles.failed
    )}>
    <progress className={styles.progress} max={max} value={value} />
    {!completed && `${Math.round((100 * value) / max)}%`}
    <div className={classnames(statusClassName, styles.status)}>{children}</div>
  </div>
)

export default ProgressBar
