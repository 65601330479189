import React, {Suspense, lazy} from 'react'
import PageFallback from 'components/Page/Fallback'

const Analytics = lazy(
  () =>
    import(
      /* webpackChunkName: "Analytics" */
      './'
    )
)

const LazyAnalytics: React.FC = () => (
  <Suspense fallback={<PageFallback />}>
    <Analytics />
  </Suspense>
)

export default LazyAnalytics
