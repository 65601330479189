import {getSessionToken} from 'utils/ramblerIdHelper'
import ApiError from 'utils/ApiError'
import {apiOrigin} from 'utils/env'
import {isString} from 'utils/checkType'
import {randomString} from 'utils/string'
import {captureException} from 'utils/sentry'
import {apiErrorCounter} from 'utils/prometheus'

const NOOP = () => {} // eslint-disable-line

export const api = async (
  endpoint: string,
  params: Record<string, any> | null | undefined = {},
  options?: RequestInit & {
    useSessionId?: boolean
  }
): Promise<{
  [field: string]: any
  responseStatus?: number
}> => {
  const sessionId = options?.useSessionId ? (await getSessionToken()).token : ''

  const requestId = `vp${randomString()}`
  const response = await fetch(`${apiOrigin}${endpoint}`, {
    body: JSON.stringify({
      id: requestId,
      sessionId,
      params
    }),
    method: 'POST',
    credentials: 'include',
    ...options,
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch((error) => {
    if (error.name !== 'AbortError') {
      apiErrorCounter.inc(1, {
        endpoint,
        errorCode: error.code != null ? String(error.code) : 'unknown'
      })

      captureException(error, {
        params
      })
      throw new ApiError(0, 'request failed', {error})
    }

    throw error
  })

  const json = await response.json().catch(NOOP)
  const responseStatus = response.status

  if (json) {
    const error:
      | {
          type: string
          subtype: string
          extra?: unknown
        }
      | null
      | undefined = json.error

    if (error) {
      const {type, subtype, extra} = error
      const resultError = new ApiError(
        -1,
        (isString(extra) && extra) || 'api error',
        {
          ...error,
          responseStatus,
          endpoint,
          requestId,
          internalRequestId: json.internalRequestId
        }
      )

      apiErrorCounter.inc(1, {
        endpoint,
        errorCode: [type, subtype].filter(Boolean).join('_') || 'unknown'
      })

      captureException(resultError, {...error, params})
      throw resultError
    }
  }

  if (!response.ok) {
    const error = new ApiError(
      responseStatus,
      response.statusText || 'Api does not respond',
      {
        responseStatus
      }
    )

    apiErrorCounter.inc(1, {
      endpoint,
      errorCode: error.code != null ? String(error.code) : 'unknown'
    })

    captureException(error, {
      params
    })
    throw error
  }

  return {
    ...json,
    responseStatus
  }
}
