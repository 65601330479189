export const isObject = (obj?: any): boolean =>
  obj !== null && typeof obj === 'object' && !Array.isArray(obj)

export const isFunction = (func?: any): boolean => typeof func === 'function'

export const isString = (str?: any): boolean => typeof str === 'string'

export const isNumber = (number?: any): boolean =>
  typeof number === 'number' && !isNaN(number)

export const isPositiveNumber = (number?: any): boolean =>
  isNumber(number) && number > 0

export const isBoolean = (boolean?: any): boolean =>
  typeof boolean === 'boolean'

export const isFalsy = (value: any): boolean =>
  value == null || value === false || value === ''

export const isFalseOrNullish = (value: any): boolean =>
  value === false || value == null

export const isEmptyStringOrNullish = (value: any): boolean =>
  value === '' || value == null

export const isEmptyArray = (value: any): boolean =>
  Array.isArray(value) && value.length === 0

export const isNotEmptyArray = (value: any): boolean =>
  Array.isArray(value) && value.length > 0

export const isEmptyStringOrEmptyArray = (value: any): boolean =>
  value === '' || isEmptyArray(value)

export const isFalsyOrEmptyArray = (value: any): boolean =>
  isFalsy(value) || isEmptyArray(value)
