export const wait = (
  ms: number,
  signal?: AbortSignal | null | undefined
): Promise<void> => {
  let reject: void | ((ev: any) => void)
  let timeout: void | number
  if (signal)
    signal.addEventListener('abort', () => {
      if (timeout) clearTimeout(timeout)
      if (reject)
        reject(new DOMException('The user aborted a timeout.', 'AbortError'))
    })

  return new Promise((resolvePromise, rejectPromise) => {
    reject = rejectPromise
    timeout = window.setTimeout(resolvePromise, ms)
  })
}
