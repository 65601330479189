import React from 'react'
import classnames from 'classnames'
import {isNotEmptyArray} from 'utils/checkType'
import styles from './styles.css'
import {todayYear} from 'utils/date'

type Props = {
  className?: string
  items?: {
    label: string
    href: string
  }[]
}

const Footer: React.FC<Props> = ({className, items}) => (
  <footer className={classnames(className, styles.root)}>
    <div className={styles.item}>© Рамблер, {todayYear}</div>
    {isNotEmptyArray(items) && (
      <ul className={styles.list}>
        {items?.map((link) => (
          <li className={styles.item} key={link.label}>
            <a className={styles.link} href={link.href}>
              {link.label}
            </a>
          </li>
        ))}
      </ul>
    )}
    <div className={styles.tag}>{__TAG__}</div>
  </footer>
)

export default Footer
