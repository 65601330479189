// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nCpzp {\n  background: none;\n  border: none;\n  padding: 0;\n  line-height: inherit;\n  font-family: inherit;\n  font-weight: inherit;\n  font-size: inherit;\n  font-weight: inherit;\n  text-align: inherit;\n  color: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContentButton/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;EACpB,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".button {\n  background: none;\n  border: none;\n  padding: 0;\n  line-height: inherit;\n  font-family: inherit;\n  font-weight: inherit;\n  font-size: inherit;\n  font-weight: inherit;\n  text-align: inherit;\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "nCpzp"
};
export default ___CSS_LOADER_EXPORT___;
