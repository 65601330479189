import React from 'react'
import classnames from 'classnames'
import styles from './styles.css'

type Props<T extends string = any> = React.HTMLProps<HTMLDivElement> & {
  tagName?: T
}

const SectionHeading: React.FC<Props> = ({
  className,
  tagName: Element = 'h2',
  ...props
}) => <Element {...props} className={classnames(className, styles.root)} />

export default SectionHeading
