// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ayqEt {\n  --rows-gap: 15px;\n  --rows-side-gap: 15px;\n  list-style: none;\n  margin: 0 0 calc(-1 * var(--rows-gap));\n  padding: 0;\n  -moz-column-gap: var(--rows-side-gap);\n       column-gap: var(--rows-side-gap);\n  -moz-column-count: 3;\n       column-count: 3;\n}\n\n  .redesigned .ayqEt {\n    --rows-gap: 20px;\n    --rows-side-gap: 20px;\n  }\n\n.PPfOc {\n  display: inline-block;\n  width: 100%;\n  margin-bottom: var(--rows-gap);\n}\n", "",{"version":3,"sources":["webpack://./src/components/ControlsList/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,gBAAgB;EAChB,sCAAsC;EACtC,UAAU;EACV,qCAAgC;OAAhC,gCAAgC;EAChC,oBAAe;OAAf,eAAe;AAMjB;;EAJE;IACE,gBAAgB;IAChB,qBAAqB;EACvB;;AAGF;EACE,qBAAqB;EACrB,WAAW;EACX,8BAA8B;AAChC","sourcesContent":[".list {\n  --rows-gap: 15px;\n  --rows-side-gap: 15px;\n  list-style: none;\n  margin: 0 0 calc(-1 * var(--rows-gap));\n  padding: 0;\n  column-gap: var(--rows-side-gap);\n  column-count: 3;\n\n  :global(.redesigned) & {\n    --rows-gap: 20px;\n    --rows-side-gap: 20px;\n  }\n}\n\n.item {\n  display: inline-block;\n  width: 100%;\n  margin-bottom: var(--rows-gap);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "ayqEt",
	"item": "PPfOc"
};
export default ___CSS_LOADER_EXPORT___;
