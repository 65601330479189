import {api} from './api'

export type UploadingInfoItem = {
  fileSize: number
  id: number
  isCompleted: null
  isFinished: true
  isNew: null
  key: string
  lastChunkUploadedAt: null | string // 2021-09-15T15:15:52.656044+03:00
  recordId: number
  s3UploadId: string
  uploadUuid: string
  uploadedBytes: number
  uploadingStart: string // 2021-09-15T15:15:52.656044+03:00
}

export const getUploadingInfo = (
  params: {
    accountCode?: string
    from: number // 1631301446
    to: number // 1631301446
    recordId?: number
    isFinished?: boolean
  },
  signal?: AbortSignal | null | undefined
): Promise<{
  result: {
    item: UploadingInfoItem[]
  }
}> =>
  api(
    `/api/v3/uploader/${
      params.accountCode ? 'getAccountUploadingInfo' : 'getUploadingInfo'
    }`,
    params,
    {
      signal
    }
  )

export type UploaderTaskStatus =
  | 'FAILED'
  | 'DONE'
  | 'SCHEDULING'
  | 'IN_PROGRESS'
  | 'WAITING_FOR_RETRY'

export type UploaderTaskType =
  | 'COMPLETING'
  | 'CONVERTING'
  | 'UPLOADING'
  | 'REPLICATING'
  | 'DELETING'
  | 'VIDEOCONVERSION_SAVING'

export type UploaderTasksItem = {
  createdAt: string // 2021-09-15T15:15:52.656044+03:00
  errorMessage: string
  id: number
  info: {
    completeInput: null | {
      partNumbers: PartNumbers
      uploadUuid: string
    }
    convertedUrls: null | string[]
    key: string
    screenshots: null | string[]
    sprite: null | string
    srcUrl: null | string
    thumbnails: null
    vtt: null | string
  }
  isNew: boolean | null | undefined
  nextRetry: null | string // 2021-09-15T15:15:52.656044+03:00
  recordId: number
  retriesLeft: number
  status: UploaderTaskStatus
  taskType: UploaderTaskType
  updateStatusDate: null | string // 2021-09-15T15:15:52.656044+03:00
  validUntil: null | string // 2021-09-15T15:15:52.656044+03:00
}

export const getUploaderTasks = (
  params: {
    accountCode?: string
    from: number // 1631301446
    to: number // 1631301446
    type?: string | null | undefined
    status?: string | null | undefined
    recordId?: string | null | undefined
  },
  signal?: AbortSignal | null | undefined
): Promise<{
  result: {
    uploaderTasks: UploaderTasksItem[]
  }
}> =>
  api(
    `/api/v3/uploader/${
      params.accountCode ? 'getAccountUploaderTasks' : 'getUploaderTasks'
    }`,
    params,
    {
      signal
    }
  )

export const restartTask = (params: {
  accountCode?: string
  uploaderTaskId: number
}): Promise<{
  result: {
    result: UploaderTasksItem
  }
}> =>
  api(
    `/api/v3/uploader/${
      params.accountCode ? 'restartAccountTask' : 'restartTask'
    }`,
    params
  )

export type PartNumbers = {
  partNumber: number
  entityTag: string
}[]

export const completeUploading = (
  params: {
    uploadUuid: string
    partNumbers: PartNumbers
  },
  signal?: AbortSignal | null | undefined
): Promise<{
  result: {
    location: string
    bucket: string
    key: string
    entityTag: string
  }
}> =>
  api('/api/v3/uploader/complete', params, {
    signal,
    credentials: 'omit'
  })

export type ConvertionTimeline = 'DAY' | 'WEEK' | 'MONTH'

type ConvertingTimeReport = (
  params: {
    from: number
    to: number
    timeline: ConvertionTimeline
    accounts?: null | undefined | string[]
  },
  signal?: AbortSignal | null | undefined
) => Promise<{
  result: {
    items: {
      [accountCode: string]: {
        timeline: string // "2021-10-18 00:00:00.0",
        millisecondsSpent: number
      }[]
    }
  }
}>

export const getConvertingTimeReport: ConvertingTimeReport = (params, signal) =>
  api('/api/v3/uploader/getConvertingTimeReport', params, {signal})

export const getAnalyticsConvertingTimeReport: ConvertingTimeReport = (
  params,
  signal
) => api('/api/v3/uploader/getAnalyticsConvertingTimeReport', params, {signal})

type StorageUsageReport = (
  params: {
    from: number
    to: number
    accounts?: null | undefined | string[]
  },
  signal?: AbortSignal | null | undefined
) => Promise<{
  result: {
    items: {
      [accountCode: string]: number
    }
  }
}>

export const getStorageUsageReport: StorageUsageReport = (params, signal) =>
  api('/api/v3/uploader/getStorageUsageReport', params, {signal})

export const getAnalyticsStorageUsageReport: StorageUsageReport = (
  params,
  signal
) => api('/api/v3/uploader/getAnalyticsStorageUsageReport', params, {signal})
