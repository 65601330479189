import React, {Fragment, useCallback, useMemo} from 'react'
import styles from './styles.css'
import Checkbox from 'rambler-ui/Checkbox'
import ControlsList from 'components/ControlsList'
import SectionHeading from 'components/SectionHeading'
import type {AccountAvailableSection} from 'types/Account'

export type Permissions = Record<AccountAvailableSection, boolean>

const PERMISSIONS_LIST: Array<Array<AccountAvailableSection | string>> = [
  ['VIDEO', 'Видео'],
  ['LIVESTREAM', 'Трансляции'],
  ['SHARING', 'Шаринг'],
  ['PHOTOBANK', 'Фотобанки']
]

export const DEFAULT_PERMISSIONS = PERMISSIONS_LIST.map(([id]) => id)

type Props = {
  onChange: (arg0: AccountAvailableSection[]) => void
  value?: AccountAvailableSection[]
}

const AccountsPermissions: React.FC<Props> = ({onChange, value = []}) => {
  const permissions = useMemo(
    () =>
      value.reduce((res, next) => {
        res[next] = true

        return res
      }, {} as Permissions),
    [value]
  )

  const onCheckCheckbox = useCallback(
    (value: any) => {
      const newState = {
        ...permissions,
        ...value
      }

      onChange(
        (Object.keys(newState) as AccountAvailableSection[]).filter(
          (item) => newState[item]
        )
      )
    },
    [onChange, permissions]
  )

  return (
    <Fragment>
      <SectionHeading>Доступный функционал</SectionHeading>

      <ControlsList className={styles.list}>
        {PERMISSIONS_LIST.map(([id, name]) => (
          <Checkbox
            checked={permissions.hasOwnProperty(id)}
            onCheck={(_, checked) => onCheckCheckbox({[id]: checked})}
            variation="awesome"
            key={id}>
            {name}
          </Checkbox>
        ))}
      </ControlsList>
    </Fragment>
  )
}

export default AccountsPermissions
