import React, {Fragment, cloneElement} from 'react'
import classnames from 'classnames'
import ContentButton from 'components/ContentButton'
import CheckboxIcon from 'components/icons/redesigned/checkbox.svg'
import styles from './styles.css'

type Props = Pick<
  React.HTMLProps<HTMLElement>,
  | 'className'
  | 'children'
  | 'onClick'
  | 'onMouseEnter'
  | 'onMouseDown'
  | 'onMouseUp'
  | 'tabIndex'
> & {
  disabled?: boolean
  selected?: boolean
  bold?: boolean
  highlight?: boolean
  container?: React.ReactElement
  color?: string
  level?: number
}

const DEDAULT_CONTAINER = <ContentButton />

export const MenuItem: React.FC<Props> = ({
  className,
  children,
  onClick,
  onMouseEnter,
  onMouseDown,
  onMouseUp,
  tabIndex,
  disabled,
  selected,
  bold,
  highlight,
  container = DEDAULT_CONTAINER,
  color,
  level
}) => {
  const style =
    color || level
      ? ({
          '--menu-item-custom-color': color || undefined,
          '--menu-item-custom-level': level || undefined
        } as React.CSSProperties)
      : undefined

  const resultChildren = (
    <Fragment>
      {selected && <CheckboxIcon className={styles.buttonIcon} />}
      <span className={styles.buttonContent}>{children}</span>
    </Fragment>
  )

  return cloneElement(
    container,
    {
      className: classnames(
        className,
        styles.button,
        (bold || selected) && styles.bold,
        (highlight || selected) && styles.highlight,
        disabled && styles.disabled
      ),
      style,
      onClick,
      onMouseEnter,
      onMouseDown,
      onMouseUp,
      disabled,
      tabIndex
    },
    resultChildren
  )
}

export default MenuItem
