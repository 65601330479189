import {api, config} from './api'
import type {
  AccountSettings,
  AccountSettingsRequest
} from 'types/AccountSettings'
import {oldHost, authOrigin} from 'utils/env'
import type {AccountAvailableSection} from 'types/Account'

export const getAccountByCode = (
  code: string
): Promise<{
  data: {
    account: {
      code: string
      id: number
      name: string
      filter_ids_for_remove_videos: number[]
      created_at: string
      require_record_uuid_after: string | null
      available_sections: AccountAvailableSection[]
    }
  }
}> =>
  api(`${authOrigin}/api/auth/account_by_host`, {
    auth_host: `${code}.${oldHost}`
  })

export const getAccount = (): Promise<{
  data: AccountSettings
}> => api(`${config.auth}/api/v2/account.json`)

export const putAccount = (
  account: Partial<AccountSettingsRequest>
): Promise<AccountSettings> =>
  api(
    `${config.auth}/api/v2/account.json`,
    {
      account
    },
    'PUT'
  )
