import {
  defaultRegistry,
  Histogram,
  Counter,
  PushGateway
} from 'prometheus-browser'
import {isDevelopment, environment} from 'utils/env'

const isDebug =
  isDevelopment || environment === 'stage' || environment === 'dev'

const DEFAULT_LABEL_NAMES = [
  'env',
  'retention',
  'service',
  'app',
  // 'appVersion',
  'deviceType',
  'account'
]

export const loadingTime = new Histogram({
  name: 'admin_loading_time',
  help: 'Общее время загрузки страницы',
  buckets: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 1, 2, 5, 10],
  labels: [...DEFAULT_LABEL_NAMES, 'endpoint']
})

let loaded = false

export const observeLoadingTime = (labels: Record<string, string>): void => {
  const time = Date.now() - performance.timeOrigin

  if (time && !loaded) {
    loadingTime.observe(time / 1000, labels)
  }

  loaded = true
}

export const apiFetchingTime = new Histogram({
  name: 'admin_api_fetching_time',
  help: 'Время запроса в API',
  buckets: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 1, 2, 5, 10],
  labels: [...DEFAULT_LABEL_NAMES, 'endpoint']
})

export const apiErrorCounter = new Counter({
  name: 'admin_api_error',
  help: 'Количество ошибок api',
  labels: [...DEFAULT_LABEL_NAMES, 'errorCode', 'endpoint']
})

const resetMetrics = (): void =>
  Object.values(defaultRegistry.collectors).forEach((collector) =>
    collector.reset()
  )

const PUSHGATEWAY_URL = process.env.PUSHGATEWAY_URL
const PUSHGATEWAY_KEY = process.env.PUSHGATEWAY_KEY

const gateway = new PushGateway({
  url: PUSHGATEWAY_URL,
  fetchOptions: {
    keepalive: true,
    headers: {
      'X-Atlas-Ath': `Basic ${PUSHGATEWAY_KEY}`
    }
  }
})

let lastPushedMetrics = ''

export const push = async (): Promise<void> => {
  const metrics = defaultRegistry.expose()

  if (metrics === lastPushedMetrics) {
    return
  }

  lastPushedMetrics = metrics

  if (isDebug) {
    console.log('prometheus', metrics) // eslint-disable-line no-console
  }

  if (isDevelopment) {
    return
  }

  await gateway.push()
  resetMetrics()
}

const METRICS_PUSH_INTERVAL = 30000
let pushInterval: number

const stopCollectMetrics = (): void => {
  clearInterval(pushInterval)
  push()
}

export const activateCollectMetrics = (): void => {
  defaultRegistry.setDefaultLabels({
    env: environment === 'production' ? 'prod' : environment,
    retention: 'annual',
    service: 'videoplatform',
    app: 'admin',
    // TODO проверить количество временных рядов и при возможности добавить версию позднее
    // appVersion: ...,
    deviceType: 'desktop'
  })

  pushInterval = window.setInterval(push, METRICS_PUSH_INTERVAL)
  window.addEventListener('beforeunload', stopCollectMetrics)
}

export const setDefaultLabels = (labels: Record<string, string>): void => {
  defaultRegistry.setDefaultLabels({
    ...defaultRegistry.defaultLabelMap,
    ...labels
  })
}
