import React, {useEffect} from 'react'
import {ErrorBoundary as SentryErrorBoundary} from '@sentry/react'
import {snackbarEvent} from 'components/rc/snackbar'

const ShowError: React.FC<{
  error: Error
  resetError: () => void
}> = (props) => {
  useEffect(() => {
    snackbarEvent({
      type: 'error',
      align: 'bottom left',
      message: props.error.toString() || 'Что-то пошло не так',
      withCloseButton: true,
      onClose: props.resetError,
      multiline: true
    })
  }, [])

  return null
}

const ErrorBoundary: React.FC<
  React.ComponentProps<typeof SentryErrorBoundary>
> = (props) => (
  <SentryErrorBoundary
    fallback={(params) => <ShowError {...params} />}
    {...props}
  />
)

export default ErrorBoundary
