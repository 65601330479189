import {createContext} from 'react'

export type ContextType = {
  subscribe: (accountCode: string, ...ids: number[]) => void
  unsubscribe: (...ids: number[]) => void
}

const DEFAULT_CONTEXT: ContextType = {
  subscribe: () => {}, // eslint-disable-line
  unsubscribe: () => {} // eslint-disable-line
}

export default createContext<ContextType>(DEFAULT_CONTEXT)
