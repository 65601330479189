import type {AccountPermissions} from 'types/Permission'
import {api} from './api'

export const getPermissionInfo = (): Promise<{
  result: {
    admin: boolean
    permissions: AccountPermissions[]
    user: {
      id: number
      email: string
    }
  }
}> => api('/api/v3/permissions/getPermissionInfo')

export const getAvailableCopyrightHolderNames = (): Promise<{
  result: {
    names: string[]
  }
}> => api('/api/v3/permissions/getAvailableCopyrightHolderNames')

export const requireCopyrightHolder = (
  params: {
    copyrightHolderName: string
  },
  signal?: AbortSignal
): Promise<{
  result: {
    allowedAccess: boolean
    user: {
      id: number
      email: string
    }
  }
}> =>
  api('/api/v3/permissions/requireCopyrightHolder', params, {
    signal
  })
