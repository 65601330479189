import React, {Fragment, useCallback, useRef, useState} from 'react'
import classnames from 'classnames'
import getDisplayName from 'rambler-ui/utils/get-display-name'
import Popup from 'components/rc/popup'
import Button from 'components/rc/button'
import styles from './styles.css'

type ButtonProps = React.ComponentProps<typeof Button>

type Params = Pick<ButtonProps, 'title' | 'children' | 'className'> & {
  buttonClassName?: string
  okButton?: string
  okButtonDisabled?: boolean
  okButtonType?: ButtonProps['type']
  cancelButton?: string
  withReject?: boolean
  width?: number
}

type ParamsList = (Params & {
  key: number
  onClose: () => void
  buttons: [string, () => any, ButtonProps['type'], boolean?][]
})[]

export type GetConfirmation = (params: Params) => Promise<void>

type ProvideProps = {
  children: (getConfirmation: GetConfirmation) => React.ReactNode
}

const NOOP = (): void => {}

const ProvideConfirmation: React.FC<ProvideProps> = (props) => {
  const keyRef = useRef(0)
  const [paramsList, setParamsList] = useState<ParamsList | never[]>([])

  const deleteParams = useCallback((key: number) => {
    setParamsList((list) => list.filter((i) => i.key !== key))
  }, [])

  const addParams: GetConfirmation = useCallback(
    (params) =>
      new Promise((resolve, reject) => {
        const key = keyRef.current++

        const buttons: ParamsList[number]['buttons'] = [
          [
            params.okButton ?? 'Ок',
            () => {
              deleteParams(key)
              resolve(undefined)
            },
            params.okButtonType ?? 'primary',
            params.okButtonDisabled
          ],
          [
            params.cancelButton ?? 'Отменить',
            () => {
              deleteParams(key)
              if (params.withReject) reject('canceled')
            },
            'secondary'
          ]
        ]

        setParamsList((list) => [
          {
            ...params,
            key,
            buttons,
            onClose: () => {
              deleteParams(key)
              if (params.withReject) reject('closed')
            }
          },
          ...list
        ])
      }),
    []
  )

  const params = (paramsList[0] ?? null) as ParamsList[number] | null

  return (
    <Fragment>
      {props.children(addParams)}

      <Popup
        className={classnames(params?.className, styles.content, 'redesigned')}
        title={params?.title}
        width={params?.width ?? 450}
        isOpen={params != null}
        onClose={params?.onClose ?? NOOP}>
        {params?.children}

        <footer className={styles.footer}>
          {params?.buttons.map(([label, onClick, type, disabled], index) => (
            <Button
              key={index}
              className={classnames(params?.buttonClassName, styles.button)}
              disabled={disabled}
              onClick={onClick}
              type={type}>
              {label}
            </Button>
          ))}
        </footer>
      </Popup>
    </Fragment>
  )
}

export type WithConfirmationProps = {
  getConfirmation: GetConfirmation
}

export function withConfirmation<Config>(
  Target: React.ComponentType<Config>
): React.FC<Omit<Config, keyof WithConfirmationProps>> {
  const WithConfirmation: React.FC<
    Omit<Config, keyof WithConfirmationProps>
  > = (props) => (
    <ProvideConfirmation>
      {(getConfirmation) => (
        <Target {...props} getConfirmation={getConfirmation} />
      )}
    </ProvideConfirmation>
  )

  WithConfirmation.displayName = `withConfirmation(${getDisplayName(Target)})`

  return WithConfirmation
}
