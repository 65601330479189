export default class ApiError extends Error {
  name: string
  message: string
  code: number
  params: Record<string, any> = {}

  constructor(code: number, message: string, rest: Record<string, any> = {}) {
    super(message)

    for (const key in rest)
      if (rest.hasOwnProperty(key)) this.params[key] = rest[key]

    this.name = 'ApiError'
    this.code = code
    this.message = message
  }
}
