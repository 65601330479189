import React, {Suspense, lazy} from 'react'
import Spinner from 'rambler-ui/Spinner'

const UsersRoutes = lazy(
  () =>
    import(
      /* webpackChunkName: "Users" */
      './UsersRoutes'
    )
)

const LazyUsersRoutes: React.FC = () => (
  <Suspense fallback={<Spinner />}>
    <UsersRoutes />
  </Suspense>
)

export default LazyUsersRoutes
