import React, {cloneElement} from 'react'
import classnames from 'classnames'
import styles from './styles.css'

type Props = {
  className?: string
  container?: React.ReactElement
  iconComponent: React.ComponentType
  rightIcon?: boolean
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLSpanElement>
  highlightOnHover?: boolean
  disabled?: boolean
  forwardRef?: React.Ref<HTMLSpanElement>
}

const IconText: React.FC<Props> = ({
  className,
  container,
  iconComponent: Icon,
  rightIcon,
  children,
  onClick,
  highlightOnHover,
  disabled,
  forwardRef,
  ...otherProps
}) => {
  const resultContainer =
    !container || disabled ? <span ref={forwardRef} /> : container

  const resultChildren = [
    <Icon key="icon" className={styles.icon} size={null} color={null} />,
    <span key="text" className={styles.text}>
      {children}
    </span>
  ]

  if (rightIcon) resultChildren.reverse()

  return cloneElement(
    resultContainer,
    {
      onClick: disabled ? null : onClick,
      className: classnames(
        className,
        styles.root,
        highlightOnHover && styles.hoverable,
        onClick && styles.clickable,
        disabled && styles.disabled
      ),
      ref: forwardRef,
      ...otherProps
    },
    resultChildren
  )
}

export default IconText
