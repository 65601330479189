import React, {useState, useCallback, useContext} from 'react'
import {Link} from 'react-router-dom'
import {logout} from 'utils/ramblerIdHelper'
import {DropdownWrapper, Dropdown} from 'components/rc/dropdown'
import CopyIcon from 'components/icons/redesigned/copy.svg'
import IconText from 'components/IconText'
import ContentButton from 'components/ContentButton'
import AppContext from 'containers/App/context'
import styles from './styles.css'
import classnames from 'classnames'

const UserDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [hasCopied, setHasCopied] = useState(false)
  const close = useCallback(() => setIsOpen(false), [])
  const open = useCallback(() => setIsOpen(true), [])

  const {user, getAccountPermissions, getPath} = useContext(AppContext)
  const {email: userEmail, avatar: userAvatar, id: userId} = user
  const {accountCode} = getAccountPermissions()

  const copyEmail = (): void => {
    if (hasCopied || !userEmail) return

    setHasCopied(true)
    navigator.clipboard.writeText(userEmail).then(() => {
      setTimeout(setHasCopied, 1e3, false)
    })
  }

  return (
    <DropdownWrapper className={styles.dropdownWrapper}>
      <ContentButton
        className={classnames(styles.userAnchor, styles.itemClickArea)}
        onClick={isOpen ? close : open}>
        <div
          className={styles.userAvatar}
          style={{backgroundImage: `url(${userAvatar})`}}
        />
      </ContentButton>

      <Dropdown
        className={classnames(styles.infoDropdown, styles.dropdown)}
        minWidth={320}
        maxWidth={320}
        isOpen={isOpen}
        onClose={close}
        display="bright">
        <div className={styles.dropdownHeading}>
          {hasCopied ? (
            'Скопировано'
          ) : (
            <IconText
              className={styles.userEmail}
              container={<ContentButton />}
              onClick={copyEmail}
              iconComponent={CopyIcon}
              rightIcon>
              {userEmail}
            </IconText>
          )}
        </div>

        {!!accountCode && !!userId && (
          <Link
            className={styles.infoDropdownItem}
            to={getPath(`users/view/${userId}/info`)}
            onClick={close}>
            Настройки пользователя
          </Link>
        )}

        <ContentButton className={styles.infoDropdownItem} onClick={logout}>
          Выход
        </ContentButton>
      </Dropdown>
    </DropdownWrapper>
  )
}

export default UserDropdown
