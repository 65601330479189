import React from 'react'
import SvgIcon from 'rambler-ui/SvgIcon'

const ArrowUpSmallIcon: React.FC<
  Omit<React.ComponentProps<typeof SvgIcon>, 'children' | 'viewBox'>
> = (props) => (
  <SvgIcon color={null} size={null} {...props} viewBox="0 0 15 15">
    <path d="m3.43 9.43.14.14a.5.5 0 0 0 .7 0L7.5 6.35l3.22 3.22a.5.5 0 0 0 .7 0l.15-.14a.5.5 0 0 0 0-.7L7.85 5a.5.5 0 0 0-.7 0L3.43 8.72a.5.5 0 0 0 0 .7M0 15V0m15 0v15" />
  </SvgIcon>
)

export default ArrowUpSmallIcon
