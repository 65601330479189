import type {Location} from 'react-router-dom'
import type {
  Permissions,
  AccountPermissions,
  ProcessedAccountPermissions
} from 'types/Permission'

const EMPTY_OBJ = {}

const ADMIN_PERMISSIONS: Permissions = {
  media: {
    can_use_ad_blocks: true,
    can_change_sprites: true,
    can_use_subtitles: true,
    can_change_youtube: true,
    can_account_block_in_ad_templates: true,
    can_create_site_access_templates: true,
    can_create_country_access_templates: true,
    can_create_metadata: true,
    can_use_custom_filters: true,
    can_export_record_filters: true,
    can_create_channels: true,
    can_edit_records: true,
    can_delete_records: true,
    can_download_records: true,
    can_manage_albums: true,
    can_create_records: true,
    can_manage_player_templates: true,
    can_manage_ad_templates: true,
    can_use_tns: true,
    can_create_public_records: true,
    can_public_records_filters: true,
    can_select_ad_template_for_records: true,
    can_change_transport: true,
    can_moderate_records: true,
    can_change_moderation: true,
    can_use_custom_screenshots: true,
    can_use_billing: true,
    can_create_public_player_templates: true,
    can_use_cuepoints: true,
    can_use_placeholders: true,
    can_view_accounts_statistics: true,
    can_use_not_private_filters: true,
    can_view_paid_content: true
  },
  yauth: {
    can_edit_api_keys: true,
    can_manage_users: true,
    can_delete_users: true,
    can_manage_roles: true,
    can_manage_account: true,
    can_view_account_stat: true,
    can_index_accounts: true
  },
  streaming: {
    can_create_translations: true,
    can_edit_foreign_translations: true,
    can_delete_foreign_translations: true,
    can_change_token: true,
    can_push_ads: true,
    can_manage_account_recording: true,
    can_manage_translation_recording: true,
    can_change_account_player_template: true,
    can_change_translation_owner: true,
    can_use_translations: true,
    can_use_billing: true,
    can_use_ad: true,
    can_view_paid_content: true
  }
}

export const preparePermissions = (
  input: AccountPermissions[],
  admin: boolean
): Record<string, ProcessedAccountPermissions> =>
  Object.fromEntries(
    input.map(({permissions, availableSections, ...rest}) => [
      rest.accountCode,
      {
        ...rest,
        availableSections: availableSections ?? [],
        permissions: admin
          ? ADMIN_PERMISSIONS
          : Object.fromEntries(
              Object.entries(permissions).map(([key, arr]) => [
                key,
                Array.isArray(arr) && arr.length
                  ? Object.fromEntries(arr.map((key) => [key, true]))
                  : EMPTY_OBJ
              ])
            )
      }
    ])
  )

export const getLocationPath = (location: Location): string =>
  `${location.pathname}${location.search}${location.hash}`
