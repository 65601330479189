import React from 'react'

const NotFound: React.FC<
  Omit<React.ComponentPropsWithRef<'svg'>, 'width' | 'height' | 'children'>
> = (props) => (
  <svg {...props} width="350" height="220">
    <defs>
      <linearGradient
        id="notFound_e"
        x1="-26.5%"
        x2="134.9%"
        y1="1628.2%"
        y2="-2866.6%">
        <stop offset="0%" stopColor="#3E7ED5" />
        <stop offset="100%" stopColor="#4058D5" />
      </linearGradient>
      <linearGradient
        id="notFound_f"
        x1="19892.1%"
        x2="20834.7%"
        y1="-1062%"
        y2="-8813%">
        <stop offset="0%" stopColor="#3E7ED5" />
        <stop offset="100%" stopColor="#4058D5" />
      </linearGradient>
      <linearGradient
        id="notFound_g"
        x1="39.5%"
        x2="78.2%"
        y1="159.7%"
        y2="-138.9%">
        <stop offset="0%" stopColor="#3E7ED5" />
        <stop offset="100%" stopColor="#4058D5" />
      </linearGradient>
      <linearGradient
        id="notFound_h"
        x1="2210.9%"
        x2="2303.2%"
        y1="29%"
        y2="-327.8%">
        <stop offset="0%" stopColor="#3E7ED5" />
        <stop offset="100%" stopColor="#4058D5" />
      </linearGradient>
      <path
        id="notFound_a"
        d="M339.3 117.2h-7.8V17.3c0-5.8-4.8-10.6-10.7-10.6h-24.4a10.7 10.7 0 00-8.6 4.4l-31 42.9A80.3 80.3 0 00244 24C232.3 8.1 215 0 192.1 0c-22.7 0-40.4 8.1-52.1 24a81.3 81.3 0 00-13.1 30.6V17.3c0-5.8-4.8-10.6-10.7-10.6H91.8a10.7 10.7 0 00-8.6 4.4L3 122.1a10.6 10.6 0 00-2 6.2v24.4c0 6 4.7 10.7 10.6 10.7h68v22.8c0 5.9 4.7 10.6 10.5 10.6h26.1c6 0 10.7-4.7 10.7-10.6v-22.8l.3 5.8c1.5 2.8 11-.2 12.8 2.3 11.8 16 29.3 24.2 52.1 24.2 22.9 0 40.2-8.2 51.9-24.2a67.4 67.4 0 005-8H284v22.8c0 5.8 4.8 10.5 10.6 10.5h26.1c6 0 10.7-4.7 10.7-10.6v-22.8h7.8c6 0 10.7-4.8 10.7-10.6v-25c0-5.8-4.8-10.6-10.7-10.6z"
      />
      <path id="notFound_c" d="M0 0h18v23H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 22)">
        <mask id="notFound_b" fill="#fff">
          <use xlinkHref="#notFound_a" />
        </mask>
        <use fill="#E5E7EA" fillRule="nonzero" xlinkHref="#notFound_a" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M290 195.5c-7.3-9.3-6.3-17.2 0-14.7 2.3 1 8.7 1.4 2.6-4.2-6.6-6.2-14.2-26.9-11.2-26 1.3 1.4 4 2.5 4.6 1 .5-1-2.7-4.3-4.7-8.9-10.9-26.6-5.5-22.2-5.5-22.2s3 1.9 2.7-.1c-.3-1.6-7.5-12.7-11-25.5a59.5 59.5 0 01-1.3-5.6.7.7 0 00-1.3 0c-2.7 10.2-7.5 27.2-11.9 31.1-1.2 3 3.6.2 4.4 1.3a83 83 0 01-12 29c-5.2 5.7 3.3 4.1 4.5 2.3 1.2-1.8 7.2-3.5-6.6 21.2-.4.8 3.4 1.2 3.5 2.3 0 4.3-.8 3-1 3.8-.2 1.9-6.3 15.1-6.5 21.1a397 397 0 0039.9-7.3c3.5-1.3 18.1 10.7 10.8 1.4z"
          mask="url(#b)"
        />
        <g fill="#FFF" fillRule="nonzero" mask="url(#b)">
          <path d="M168.8 194.5c-3.8-4-6.6-18.1-1.8-16.2 1.8.7 4.6.6 0-3.7-5-4.7-10.8-20.4-8.6-19.8 1 1 3 1.9 3.6.8.4-.8-2.1-3.3-3.6-6.8-8.3-20.3-4.2-17-4.2-17s2.3 1.5 2 0c-.2-1.4-7.3-12.3-9.4-23.7a.5.5 0 00-1 0c-2 7.7-8 22.4-9.4 24.7-1 2.3 2.5-.4 3.1.5.7.8-4.4 17.2-8.5 21.7-4 4.3 2.5 3 3.4 1.7 1-1.4 3 .6-7.4 19.5-.4.5 4.8.3 4.9 1 0 3.4-10 10-10.2 10.6-.2 1.4 9.4 7 9.3 11.6 8.5.1 30 0 34-1.5 2.7-1 7.7.6 3.8-3.4z" />
          <path d="M132.7 196.6c-3.5-4.7-2.2-8.2 1.8-6.6 1.4.6 4-7.5 0-10-5.5-3.4-7.3-10-5.5-9.5.9.9 3.3 1.7 3.7.9.4-.7-2.4-3-3.7-6-6.8-17.2-3.4-14.3-3.4-14.3s1.9 1.2 1.7-.1c-.3-1.2-6.1-10.3-7.8-20a.4.4 0 00-.4-.4.4.4 0 00-.4.3c-1.7 6.6-6.6 19-7.8 21-.8 2 2.1-.4 2.6.4a50 50 0 01-7 18.3c-3.3 3.6 2 2.6 2.8 1.4.8-1.1 3 5.5-5.6 14.2-8.3 8.3-.8 6.4.6 6 3.1-.8 3.2 7.2 3 7.7-.2.5-1.6 2.6-3.6 2.6s2.7-1.3 2.6.7a161.6 161.6 0 005.7-1c6.4-1.1 14.5 2 17 1 2.3-.8 7.2-2 3.7-6.6z" />
        </g>
      </g>
      <path
        fill="#FFF"
        d="M160.6 79.2l-1.3.5c-1.7.7-3.5.6-5.2 0-3.4-1-6-3.3-7.7-6.6l-.8-1.8v-.1a49.4 49.4 0 0012 4.4 6 6 0 003.5.1c.7-.3 1.2-.8 1.6-1.3.6-.6 1.2-.8 1.9-.4.2.1.5.5.6.8.3.6.5.6 1 .1 1-1.2 2-2.4 3.3-3.3a60.8 60.8 0 013.6-2.4c2.2-1.4 4-3.3 5.3-5.6.1-.3.2-.8.7-.7l.2 1c0 2.5-1 4.6-2.6 6.5-2 2.3-4.2 4.3-6.5 6.2-.6.5-1.3.8-2 1-1.2.6-1.7 1.4-1.4 2.7l.7 2c.3.7.3 1.2-.4 1.7l-1.8 1a17.8 17.8 0 01-5.4 1 7 7 0 01-1.2-.1c-.8-.1-1-.6-.7-1.2l1.7-2.9.7-1.8.2-.8"
      />
      <g transform="translate(72.8 109.8)">
        <mask id="notFound_d" fill="#fff">
          <use xlinkHref="#notFound_c" />
        </mask>
        <path
          fill="#FFF"
          d="M0 0h.4c3 .6 6.1.3 9.2.4.4 0 .7.2.9.6l.7 2.1c.2.4.4.5.7.6h1.8c.5.1.7.3.7.8l.2 1.8c0 .5.2.7.6.8.7.3 1.3.8 1.8 1.5a5 5 0 011 2.8 14.7 14.7 0 01-.5 4.5l-1 3.6-1.2 3.4-.4-.5c-.8-1.3-.7-2.6-.4-4 .3-1.2.5-2.5.3-3.9a8.4 8.4 0 00-1.4-4c-.4-.6-1-.9-1.8-1a8 8 0 00-5.4 2.1l-.4.4c-.3.2-.5 0-.6-.3a2.3 2.3 0 010-.7C5.7 9.3 6.5 8 7.8 7c.7-.6.8-1.5.3-2.3a4.2 4.2 0 00-2.2-1.8L.4.4C.2.4 0 .2 0 .1"
          mask="url(#d)"
        />
      </g>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M199.5 209.4s0 3.5-1.2 4.3L196 215l.6 3.3 9.1-2.4.2-1.2a7.5 7.5 0 01-.5-1.3 34.3 34.3 0 010-4h-6z"
      />
      <path
        fill="#F1F6FF"
        d="M203 216.3s-1.1-4.8 0-5.8c.8-.7 1.8-1 3-.9-.3.6-.5 1.4-.5 2.3 0 1.5.3 2.7.6 3.1.2.5-3 1.3-3 1.3z"
      />
      <path
        fill="#424C65"
        fillRule="nonzero"
        d="M196.1 215a18.8 18.8 0 00-5.2 2.2c-1 .7-.5 1.6 0 1.8a84 84 0 0015.1 0c.7-.6.6-3.2 0-4.3-10.9 2.5-9.9.3-9.9.3z"
      />
      <path
        fill="#F1F6FF"
        d="M228.3 216.1v-.9a10.5 10.5 0 01-.7-2.5v-3l-2.6.4v4.6c0 1.2.3 2.1.8 2.8l2.6-1.4z"
      />
      <path
        fill="#424C65"
        fillRule="nonzero"
        d="M220.7 214.8s2.2 2 3.8 1.9a9 9 0 003.9-1.5s1.8 3.2.8 4c-1 .7-4.4.7-4.8.6-2.4 0-4.8 0-5-1.1a6.9 6.9 0 011.3-3.9z"
      />
      <path
        fill="#5081FF"
        fillRule="nonzero"
        d="M204.2 121.1s1.8 11.5 1.4 15.4c-1.4 22.3-8.6 62-8.5 73.9 2.2 1.5 8.7 1.4 11 .2 1.2-4.1.8-12 1.5-21 .3-3.8 1-8.5 2.3-12 .6-2.9 1.5-8.6 2.7-13.5 1.5-14.6 3.5-10.9 3.6-9.7l1.1 23.8s-1 23.6-.5 32.5a18.6 18.6 0 0010.9 0s.8-12.8 1.1-30.8c.3-12.1 4-24.8 2.5-31.4a65.6 65.6 0 00-4.4-12.5c-1.5-3.2 2.1-10.3 2.1-10.3l-26.8-4.6z"
      />
      <path
        fill="url(#e)"
        fillRule="nonzero"
        d="M8.9 42.3c-.4-.2-1.6 0-3 .6l3.7 1a2.6 2.6 0 00-.7-1.6z"
        opacity=".2"
        style={{
          mixBlendMode: 'multiply'
        }}
        transform="translate(179.5 72.8)"
      />
      <path
        fill="url(#f)"
        fillRule="nonzero"
        d="M4.4 42.6a1.5 1.5 0 00-.2 1l1.7-.7a5 5 0 00-1.5-.3z"
        opacity=".1"
        style={{
          mixBlendMode: 'multiply'
        }}
        transform="translate(179.5 72.8)"
      />
      <path
        fill="url(#g)"
        fillRule="nonzero"
        d="M19.4 42.2c.7-.3 13.1 4 10.7 1-2.5-3.2-9-14-6.2-13 1 .4 3.8.6 1.1-1.8-.6-.6.1-.3-.5-1.3-.3 1.2-3 2.8-6.6 8.5-.4 1.5 1.6-.4 2 .3.4.6-3 7.6-5.7 11.2 0 1.3 2 .8 4.5 0 .2-2.4.3-4.7.7-4.9z"
        opacity=".2"
        style={{
          mixBlendMode: 'multiply'
        }}
        transform="translate(179.5 72.8)"
      />
      <path
        fill="url(#h)"
        fillRule="nonzero"
        d="M18 35.6c-.5 1.5 1.5-.4 2 .2s-3 7.7-5.8 11.3c0 1.3 2 .8 4.5 0 .1-2.4.2-4.7.6-5 .7-.2 13.1 4.1 10.7 1-2.4-3.2-9-14-6.2-12.9 1 .4 3.8.6 1.2-1.8"
        opacity=".1"
        style={{
          mixBlendMode: 'multiply'
        }}
        transform="translate(179.5 72.8)"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M246.3 115.5c-1.5-2.3-2.5-3-4-5.1-3.4-4.6-5.5-9.8-8-11.2a20 20 0 00-4.8-2.2c-2.2-.8-3.3-2.8-3.7-2.8a9.7 9.7 0 01-2.1-.5 22.1 22.1 0 01-1.9-7.4 2.3 2.3 0 001.7-1.7 2 2 0 00-1-2.5c-.4-.1-.7-1-1-.9 0-.4-.2.4-.2.4l-.8.5-.3-2.7c-.3-2.8-2.7-2.5-5.6-2.1l1-.5c-2.9.3-5.9-.2-5.8 4.4v2a5.6 5.6 0 01-1 3.3c-.2.4 1.4.3 1.4.3l.4 2c.3 1.5.5 2.3 2 2.2l2-.4.3 3c0 1.8-2.6 2.4-2.6 2.4s-6.2.7-8.2 2.3c-.7.7-5.1 5.2-4 6 3.7 2.8 16.2 3.8 17 3.8 1 .1 3.2 8 3.2 8s3.3 7.4 5.2 7.5c2 0 8.2-4.6 8.2-4.6a47.7 47.7 0 015.4 3.4c2.7 2 7.5 2 9 .4 1.4-1.3 0-5.2-1.8-7.3z"
      />
      <path
        fill="#5081FF"
        fillRule="nonzero"
        d="M213.6 95.5c-1.1.1-3.4.8-4.1 1-2.3 3.3-2.2 7.6-2.8 10.5-3 1.3-2.7 7-2.7 7l10 3.7 18.9 1.8s-6 1-6.8-1c-.5-1.3-1.6-7-2-8.6-.5-1.7 5-1.4 5-2.8-.2-4.5 3-9.1 3-9.1s-6.4-3.1-7.5-3.5c-.3.3-.5 4.6-6.2 4.4-3.7 0-5.3-1.2-4.8-3.4z"
      />
      <path
        fill="#78A7FF"
        fillRule="nonzero"
        d="M193.2 104.4L197 132l14.6-5.4-3.8-27.6z"
      />
      <path
        fill="#BED3FC"
        fillRule="nonzero"
        d="M211.7 126.7l9 9.4-3.9-27.8-8.9-9.2zm-27.3-4.6l12.7 10-4-27.7-12.6-10z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M183 112.1s-3.2-3.3-2.6-4.8c.7-1.5 1.3-2.8 2-3 .6-.3 3.3-.8 3-.4-.4.3-2 1-2.4 1.6-.3.6 1.5-.7 2.6-.8 1 0 .6 2.5-.4 3.2a9.2 9.2 0 00-2 2.2 3 3 0 00-.2 2z"
      />
      <path
        fill="#78A7FF"
        fillRule="nonzero"
        d="M233.8 119a2.8 2.8 0 01-1.4-.3c-3-1.2-2.7-4-3.3-4.4a9.1 9.1 0 00-2.3-.6c-1.3-.3-1-2.9.2-3.3.7-.2 3.6.6 3.4.3 0-.3-2-.6-2.9-1.2-.1-.2-.2-.6.2-.6s3 0 4.5 1.2l.4.5-1-7.6-14.8 5.3 4 27.8 14.6-5.4-1.6-11.7z"
      />
      <path
        fill="#424C65"
        fillRule="nonzero"
        d="M210.8 78.1a2.6 2.6 0 012-.6 6 6 0 014.9 4.4c.4 1.8 2 4 2.3 2a3 3 0 01.4-1.7c.6-.6 3-1.3 2.1 2.3a2.2 2.2 0 01-1.4 1.5s1 5.4 2 7.4c1 1.6 9.6 4.6 10.8 5.5.5-1 3-2 1-6.3s-9.6-2.7-8.8-5.7c1-6.8.4-8.7-3.4-12.1-3-2.7-8-1.2-10.2.4a7 7 0 00-2.7 5 2.6 2.6 0 011-2z"
      />
      <path
        fill="#F1F6FF"
        fillRule="nonzero"
        d="M214.5 90.6s3.5-.5 4.5-1.4a2.8 2.8 0 001-1.7 3.4 3.4 0 01-1.7 3.7c-1.1.5-2.3 1-3.6 1.2l-.2-1.8zm17.4 14.8s5.7 8.4 6.8 9.2c1.2.7 6.7.9 7.6 4.4-.4-1.4-4.6-1.5-4.6-1.5a17.7 17.7 0 01-6.3-2.3 33 33 0 00-2.8-4.6l-.7-5.2z"
      />
      <path
        fill="#426FE2"
        fillRule="nonzero"
        d="M202 211.5c1.7 0 4.7-.3 6-.9 2.5-5.3 2-11.9 2.3-18.2.5-7.7 1.6-14.8 1.6-14.8a88.4 88.4 0 004-16c.7-6.7 1.4-9.2 2.2-7.6 0 0-.4-3.8-3.2-3.8-2.8 0-4.3 2.5-5.9 11.3-.5 3.4-1 11.5-2.2 16-.8 3.9-.6 5.7-.8 12.5-.1 4-.4 8.2-.8 11.8-.4 5.3-1.5 9-3.3 9.7zm26.5-78.3s-.5 1.5 1.1 4.5c.9 1.5 3.6 9.1 3.9 12 .5 5.6-1.8 19.5-2.5 26-.2 10.4-1.3 35-1.3 35-1.5.6-2 .6-4 .8.3-3.5-.2-7.8 0-12.4.2-4.7 1.3-9.8 1-13.1-.7-6.3 0-11.4 0-11.4s1.4-7.9 2.2-15.5c.3-3.4.5-6.7.4-9.3-.4-3.9-2.6-5.9-3.6-8.3a44 44 0 01-2.1-6.4l5-1.9z"
      />
    </g>
  </svg>
)

export default NotFound
