// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GA5er {\n  margin-top: 20px;\n}\n\n.haUH2 {\n  display: none;\n}\n\n.wGCYs {\n  position: absolute !important;\n  left: var(--layout-side-padding);\n}\n\n.UVdKf {\n  position: absolute;\n  right: var(--layout-side-padding);\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  max-width: 380px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/LayoutProvider/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,iCAAiC;EACjC,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".footer {\n  margin-top: 20px;\n}\n\n.hidden {\n  display: none;\n}\n\n.footerLeft {\n  position: absolute !important;\n  left: var(--layout-side-padding);\n}\n\n.footerRight {\n  position: absolute;\n  right: var(--layout-side-padding);\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  max-width: 380px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "GA5er",
	"hidden": "haUH2",
	"footerLeft": "wGCYs",
	"footerRight": "UVdKf"
};
export default ___CSS_LOADER_EXPORT___;
