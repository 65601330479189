// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CKztB {\n  font-size: 15px;\n  line-height: 20px;\n}\n\n  .CKztB p {\n    margin: 0 0 20px;\n    word-break: break-word;\n  }\n\n.eWrg6 {\n  display: flex;\n  margin-top: 20px;\n}\n\n.w6pev {\n  flex: auto;\n  width: 50%;\n  min-width: 0;\n}\n\n.w6pev:not(:first-child) {\n    margin-left: 20px;\n  }\n", "",{"version":3,"sources":["webpack://./src/components/ConfirmationProvider/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AAMnB;;EAJE;IACE,gBAAgB;IAChB,sBAAsB;EACxB;;AAGF;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,UAAU;EACV,YAAY;AAKd;;AAHE;IACE,iBAAiB;EACnB","sourcesContent":[".content {\n  font-size: 15px;\n  line-height: 20px;\n\n  p {\n    margin: 0 0 20px;\n    word-break: break-word;\n  }\n}\n\n.footer {\n  display: flex;\n  margin-top: 20px;\n}\n\n.button {\n  flex: auto;\n  width: 50%;\n  min-width: 0;\n\n  &:not(:first-child) {\n    margin-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "CKztB",
	"footer": "eWrg6",
	"button": "w6pev"
};
export default ___CSS_LOADER_EXPORT___;
