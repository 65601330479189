import React, {Fragment} from 'react'
import {Helmet} from 'react-helmet'
import Page from 'components/Page'
import ContentMessage from 'components/ContentMessage'
import NoAccess from 'components/images/NoAccess'
import styles from './styles.css'

type Props = {
  title?: string
  message?: React.ComponentProps<typeof ContentMessage>['message']
  control?: React.ComponentProps<typeof ContentMessage>['control']
}

const NoAccessPage: React.FC<Props> = ({
  title = '',
  message = '',
  ...contentProps
}) => (
  <Page className={styles.fullSize}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <ContentMessage
      {...contentProps}
      message={message}
      className={styles.fullHeight}
      heading={title}
      icon={<NoAccess />}
    />
  </Page>
)

NoAccessPage.defaultProps = {
  title: 'Доступ запрещен',
  message: (
    <Fragment>
      Поздравляем, вы нашли тайную дверь.
      <br />
      Но дальше вы пройти не сможете.
    </Fragment>
  )
}

export default NoAccessPage
