// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZOTtn {\n  display: inline-flex;\n  line-height: 15px;\n  color: var(--rui-purpleBlue);\n  cursor: pointer;\n  transition: color .2s;\n}\n\n  .ZOTtn:hover {\n    color: var(--rui-ramblerBlue);\n  }\n\n.DQcsj {\n  fill: currentColor;\n  transition: fill .2s;\n  width: 15px;\n  height: 15px;\n}\n\n.hz0tG {\n  transform: scaleY(-1);\n}\n", "",{"version":3,"sources":["webpack://./src/components/ButtonWithArrow/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,4BAA4B;EAC5B,eAAe;EACf,qBAAqB;AAKvB;;EAHE;IACE,6BAA6B;EAC/B;;AAGF;EACE,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".root {\n  display: inline-flex;\n  line-height: 15px;\n  color: var(--rui-purpleBlue);\n  cursor: pointer;\n  transition: color .2s;\n\n  &:hover {\n    color: var(--rui-ramblerBlue);\n  }\n}\n\n.icon {\n  fill: currentColor;\n  transition: fill .2s;\n  width: 15px;\n  height: 15px;\n}\n\n.isInverted {\n  transform: scaleY(-1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ZOTtn",
	"icon": "DQcsj",
	"isInverted": "hz0tG"
};
export default ___CSS_LOADER_EXPORT___;
