import React from 'react'
import getDisplayName from 'rambler-ui/utils/get-display-name'
import Context, {
  type ContextType
} from 'containers/PollingRecordsProvider/context'

export type WithPollingRecordsProps = {
  pollingRecords: ContextType
}

export default function <Config>(
  Target: React.ComponentType<Config>
): React.FC<Omit<Config, keyof WithPollingRecordsProps>> {
  const WithPollingRecords: React.FC<
    Omit<Config, keyof WithPollingRecordsProps>
  > = (props) => (
    <Context.Consumer>
      {(pollingRecordsContext) => (
        <Target {...(props as Config)} pollingRecords={pollingRecordsContext} />
      )}
    </Context.Consumer>
  )

  WithPollingRecords.displayName = `withPollingRecords(${getDisplayName(
    Target
  )})`

  return WithPollingRecords
}
