import {api, config} from './api'
import type {Response as MeResponse} from 'types/Me'

export const getMe = (): Promise<MeResponse> => api(`${config.auth}/me`)

export type UsersRequest = {
  page: number
  per_page: number
  order?: string
  order_dir?: string
}

export type UserResponse = {
  admin: boolean
  confirmed_at: string
  email: string
  id: number
  last_sign_in_at: null | string
  omniauth_name: null | string
  roles: string[]
  role_ids: number[]
  status: string
}

export type User = Omit<UserResponse, 'confirmed_at' | 'last_sign_in_at'> & {
  confirmed_at: Date
  last_sign_in_at: null | Date
}

export const getUsers = (
  payload?: UsersRequest | null | undefined,
  signal?: AbortSignal
): Promise<{
  data: UserResponse[]
  total_entries: number
}> =>
  api(`${config.auth}/api/v2/users.json`, payload, 'GET', {
    signal
  })

export const getUser = (
  id: number,
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  } = {}
): Promise<{
  data: UserResponse
}> =>
  api(`${origin}/api/v2/users/${id}.json`, null, 'GET', {
    signal
  })

type ApiKeyResponse = {
  data: {
    api_key: string
  }
}

export const getApiKey = (
  id: number,
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  } = {}
): Promise<ApiKeyResponse> =>
  api(`${origin}/api/v2/users/${id}/api_key.json`, null, 'GET', {
    signal
  })

export const resetApiKey = (
  id: number,
  signal?: AbortSignal
): Promise<ApiKeyResponse> =>
  api(
    `${config.auth}/api/v2/users/${id}/reset_api_key.json`,
    {
      user: {
        user: id
      }
    },
    'PUT',
    {
      signal
    }
  )

export type InvitationResponse = {
  invitation_token: string
  invitation_url: string
  user_id: number
}

export const postUser = (
  email: string,
  origin: string = config.auth
): Promise<{
  data: InvitationResponse
}> =>
  api(
    `${origin}/api/v2/users.json`,
    {
      user: {
        email
      }
    },
    'POST'
  )

export type UserRequest = {
  email?: string
  role_ids?: number[]
}

export const putUser = (
  id: number,
  user: UserRequest,
  {
    origin = config.auth,
    signal
  }: {
    origin?: string
    signal?: AbortSignal
  } = {}
): Promise<{
  data: {
    data: UserResponse
  }
}> =>
  api(
    `${origin}/api/v2/users/${id}.json`,
    {
      user
    },
    'PUT',
    {
      signal
    }
  )

export const deleteUser = (id: number): Promise<void> =>
  api(`${config.auth}/api/v2/users/${id}.json`, null, 'DELETE')

export type SignInItemResponse = {
  datetime: string
  ip: string
}

export type SignInItem = SignInItemResponse & {
  datetime: Date
}

export const getSignInHistory = (
  id: number,
  signal?: AbortSignal
): Promise<{
  data: SignInItemResponse[]
}> =>
  api(`${config.auth}/api/v2/users/${id}/sign_in_history.json`, null, 'GET', {
    signal
  })

export const lockUser = (
  id: number,
  signal?: AbortSignal
): Promise<{
  data: UserResponse
}> =>
  api(
    `${config.auth}/api/v2/users/${id}/lock.json`,
    {
      user: {
        user: id
      }
    },
    'POST',
    {
      signal
    }
  )

export const unlockUser = (
  id: number,
  signal?: AbortSignal
): Promise<{
  data: UserResponse
}> =>
  api(
    `${config.auth}/api/v2/users/${id}/unlock.json`,
    {
      user: {
        user: id
      }
    },
    'POST',
    {
      signal
    }
  )
