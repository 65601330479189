import type {
  AccountAvailableSection,
  AccountItemResponse,
  AccountResponse
} from 'types/Account'
import {api} from './api'

export const getAllAccounts = (
  signal: AbortSignal | null | undefined
): Promise<{
  result: AccountItemResponse[]
}> =>
  api('/api/v3/admin/getAllAccounts', null, {
    signal
  })

export const getAnalyticsAllAccounts = (
  signal: AbortSignal | null | undefined
): Promise<{
  result: AccountItemResponse[]
}> =>
  api('/api/v3/admin/getAnalyticsAllAccounts', null, {
    signal
  })

export const getAccount = (
  accountId: number
): Promise<{
  result: AccountResponse
}> =>
  api('/api/v3/admin/getAccount', {
    accountId
  })

export const createAccount = (params: {
  name: string
  code: string
  availableSections: AccountAvailableSection[]
}): Promise<{
  result: AccountResponse
}> => api('/api/v3/admin/createAccount', params)

type AccountStatusResult = {
  lock: boolean
}

export const updateAccountStatus = (
  accountId: number,
  lock: boolean
): Promise<{
  result: AccountStatusResult
}> =>
  api('/api/v3/admin/updateAccountStatus', {
    accountId,
    lock
  })

type EncryptorStatusResult = {
  useEncryptor: boolean
}

export const getEncryptorStatus = (
  accountId: number
): Promise<{
  result: EncryptorStatusResult
}> =>
  api('/api/v3/admin/getEncryptorStatus', {
    accountId
  })

export const getEncryptorStatusByCode = (
  code: string
): Promise<{
  result: EncryptorStatusResult
}> =>
  api('/api/v3/admin/getEncryptorStatusByCode', {
    code
  })

export const updateEncryptorStatus = (
  accountId: number,
  useEncryptor: boolean
): Promise<{
  result: EncryptorStatusResult
}> =>
  api('/api/v3/admin/updateEncryptorStatus', {
    accountId,
    useEncryptor
  })

export const updateVideoAutoRemovalFilter = (
  accountCode: string,
  filterId: number
): Promise<{
  result: {
    accountCode: string
    filterId: number[]
  }
}> =>
  api('/api/v3/admin/updateVideoAutoRemovalFilter', {
    accountCode,
    filterId
  })

export const deleteVideoAutoRemovalFilter = (
  accountCode: string,
  filterId: number
): Promise<{
  result: {
    accountCode: string
    filterId: number[]
  }
}> =>
  api('/api/v3/admin/deleteVideoAutoRemovalFilter', {
    accountCode,
    filterId
  })

export type AccountAdSettingsPayload = {
  accountId: number
  adDisableDuration: null | number
  adDisableDurationEnabled: boolean
}

export type AccountAdSettingsResponse = {
  accountId: number
  adDisableDuration: number
  adDisableDurationEnabled: boolean
}

export const updateAccountAdSettings = (
  payload: AccountAdSettingsPayload
): Promise<{
  result: AccountAdSettingsResponse
}> => api('/api/v3/admin/updateAccountAdSettings', payload)

export const updateAccountAvailableSections = (payload: {
  code: string
  availableSections: AccountAvailableSection[]
}): Promise<void> =>
  api('/api/v3/admin/updateAccountAvailableSections', payload)
