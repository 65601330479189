import React, {cloneElement} from 'react'
import Button from 'rambler-ui/Button'
import classnames from 'classnames'
import styles from './styles.css'

type Props = React.HTMLProps<HTMLDivElement> & {
  title: string
  control?: React.ReactElement<typeof Button>
}

const PageMessage: React.FC<Props> = ({
  className,
  children,
  title,
  control,
  ...props
}) => (
  <div {...props} className={classnames(className, styles.root)}>
    <h3 className={styles.heading}>{title}</h3>
    <div className={styles.content}>{children}</div>
    {control &&
      cloneElement(control, {
        className: styles.control
      })}
  </div>
)

export default PageMessage
