// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R0tee {\n  flex: none;\n  display: flex;\n  color: var(--rui-cloudGray);\n  min-width: var(--layout-page-width);\n  padding: 30px var(--layout-side-padding) 15px;\n  margin-top: auto;\n  font-size: 11px;\n  line-height: 25px;\n}\n\n.JylyH {\n  flex: none;\n  display: flex;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n}\n\n.Cgh9_ {\n  flex: none;\n  margin-right: 20px;\n}\n\n.Lcw8r:hover {\n    text-decoration: underline;\n  }\n\n.v4FhF {\n  margin-left: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/styles.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,2BAA2B;EAC3B,mCAAmC;EACnC,6CAA6C;EAC7C,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAGE;IACE,0BAA0B;EAC5B;;AAGF;EACE,iBAAiB;AACnB","sourcesContent":[".root {\n  flex: none;\n  display: flex;\n  color: var(--rui-cloudGray);\n  min-width: var(--layout-page-width);\n  padding: 30px var(--layout-side-padding) 15px;\n  margin-top: auto;\n  font-size: 11px;\n  line-height: 25px;\n}\n\n.list {\n  flex: none;\n  display: flex;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n}\n\n.item {\n  flex: none;\n  margin-right: 20px;\n}\n\n.link {\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.tag {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "R0tee",
	"list": "JylyH",
	"item": "Cgh9_",
	"link": "Lcw8r",
	"tag": "v4FhF"
};
export default ___CSS_LOADER_EXPORT___;
