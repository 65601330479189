import React from 'react'

const NoAccess: React.FC<
  Omit<React.ComponentPropsWithRef<'svg'>, 'width' | 'height' | 'children'>
> = (props) => (
  <svg {...props} width="350" height="220">
    <defs>
      <path
        id="noAccess_a"
        d="M1.83 19.6s1.21-.4 1.41-3.94c-.5-.6-1.61-2.72-1.82-3.43-1.61-4.95.4-8.69 4.45-10.8C9.1-.3 10.82-.3 14.55 2.82c1.32 1.12 2.02 3.44 1.92 5.16.7 1.41 1.82 3.13 1.82 3.53 0 .6-1.41.71-1.41 1.42-.1 3.23-.4 3.63-.81 4.24-.6.9-1.92 1-3.74 1.51-.5 1.42-1.82 3.64-1.62 5.05-1.1-1.3-2.52-2.72-3.83-3.33-2.73-1.21-5.05-.8-5.05-.8z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M82.2 215.96s-7.67-1.73-8.75-1.9c-1.62-.26-3.25.6-3.25.6l-1.53-3.46 10.1-4.33 1.18.43s.1.6.36 1.3c.54 1.39 4.52 2.17 4.52 2.17l-2.62 5.19z"
      />
      <path
        fill="#F1F6FF"
        d="M77.44 207.18l2.27.22c.16.5.39.87.68 1.1.3.24 1.29.42 2.97.55v3.12s-7.94-1.73-10.78-1.13c-1.06-.9 4.86-3.86 4.86-3.86z"
      />
      <path
        fill="#424C65"
        fillRule="nonzero"
        d="M70.53 214.16s-3.63 1.54-6.53 1.8c-1.45.08-1.63-.94-1.17-1.54.9-1.2 13.05-10.5 14.05-10.58 1-.08 2.72 2.3 2.9 3.58-11.79 5.55-9.25 6.74-9.25 6.74z"
      />
      <path
        fill="#E5E7EA"
        fillRule="nonzero"
        d="M333.38 114.59c7.67-8.58 11.71-19.43 11.71-31.85 0-34.6-27.88-60.74-64.94-60.74-15.15 0-29.9 2.66-43.63 7.99a10.96 10.96 0 00-7.07 10.06c-13.73-13.9-31.81-17.95-48.27-17.95-16.97 0-35.56 4.24-49.5 18.93V35.8c0-6.01-4.94-10.84-11.1-10.84H95.23a11.19 11.19 0 00-8.99 4.53L3.12 141.71A10.16 10.16 0 001 148v24.76c0 6.01 4.95 10.84 11.11 10.84h70.3v22.98c0 6.01 4.94 10.85 11.1 10.85h27.07c6.16 0 11.1-4.83 11.1-10.85v-4.04c13.94 14.69 32.63 17.45 49.5 17.45 15.75 0 32.92-3.75 46.35-16.27.6 3.94 3.34 7.3 7.27 8.58 14.45 5.03 29.6 7.6 45.05 7.6 40.2 0 70.6-28.4 70.6-66.07a53.9 53.9 0 00-17.08-39.25z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M287.62 100.62V83.45c0-13.93-9.8-25.24-21.81-25.24s-21.82 11.4-21.82 25.24v17.07h43.63v.1z"
      />
      <path
        fill="#E5E7EA"
        fillRule="nonzero"
        d="M269.14 77.8a3.36 3.36 0 00-3.33-3.33 3.36 3.36 0 00-3.33 3.33c0 1.41.8 2.63 2.02 3.13l-.71 6.56c0 .3.2.61.6.61h2.93c.3 0 .61-.3.61-.6l-.6-6.57a3.65 3.65 0 001.81-3.13z"
      />
      <path
        fill="#5081FF"
        fillRule="nonzero"
        d="M111.9 178.6s13.93 33.62 7.06 38.57c-2.83 2.02-36.96.3-36.96.3s-1.21-8.78 1.31-12.52c6.46-.3 19.19.1 23.13.5.4 0 .7-.4.5-.8l-2.22-4.14s-4.24-9.7-6.77-11.72c-7.47-7.27-6.36-13.73-6.36-13.73l20.3 3.53z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M144.1 208.14s1.64 3.76 2.42 4.62a5.78 5.78 0 002.94 1.54l-1.04 3.68-10.64-2.74.13-1.17c0-.36.26-.9.35-1.5.17-1.02-.92-4.69-.92-4.69l6.76.26z"
      />
      <path
        fill="#F1F6FF"
        d="M137.23 208.55s.85 3.1.85 4.22c0 .75-.14 1.46-.42 2.13l5.08 1.1-2.39-7.45h-3.12z"
      />
      <path
        fill="#424C65"
        fillRule="nonzero"
        d="M149.62 214.2s3.8.92 6.23 2.52c1.2.84.6 1.76-.18 1.93-1.47.34-16.95.59-17.81-.08-.78-.6-.61-3.45.08-4.63 12.89 2.78 11.68.25 11.68.25zm22.16-64.29l48.88-11.51a4.42 4.42 0 004.14 1.2 4.07 4.07 0 003.13-4.03l1.22-.3.3 1.2c.3 1.02 1.41 1.62 2.53 1.42a2 2 0 001.51-2.32l-.3-1.21 5.55-1.32 4.04 14.65 20.9-4.95-4.03-14.65 4.24-1c2.73-.61 4.44-3.24 3.64-5.86-.71-2.63-3.54-4.14-6.27-3.54l-30.8 7.27-.3-1.2c-.3-1.02-1.42-1.62-2.53-1.42-1.1.3-1.71 1.31-1.41 2.32l.3 1.21-1.21.3a4.17 4.17 0 00-4.85-2.12 4.23 4.23 0 00-3.03 2.93l-48.78 11.42c-2.52.6-5.25-.5-6.66-2.63-5.66-8.69-15.05-13.53-23.74-11.41-11.51 2.72-17.67 16.36-13.83 30.6 3.93 14.14 16.46 23.43 27.97 20.7 8.79-2.02 14.44-10.4 14.95-20.6a5.66 5.66 0 014.44-5.15zm-14.24 5.96c1.52 5.55-2.32 11.2-8.58 12.02-4.65.6-9.2-1.92-10.91-6.06a9.03 9.03 0 01-.3-6.67c.8-2.12.1-4.64-1.72-6.16a9.44 9.44 0 01-3.23-5.96c-.6-4.34 2.12-8.58 6.46-10.1 5.86-2.02 12.22 1.21 13.74 6.77.5 1.82.4 3.63-.1 5.35a5.76 5.76 0 001.81 6.36 8.91 8.91 0 012.83 4.45z"
      />
      <path
        fill="#426FE2"
        fillRule="nonzero"
        d="M105.13 150.31s-1.62-6.26 2.12-7.77c-.3-.2-2.93.6-5.05 3.43-1.32 2.22-1.92 2.53-2.93 10-.6 4.64-2.73 8.79-4.04 12.62-.2.61 5.15 5.66 3.53 6.47-.9.6-4.74-5.05-4.44-2.83-.1 1.21-2.94.7-2.64 1.92-1.05 7.07 4.96 13.43 6.68 15.25 2.73 2.93 5.86 11.2 8.48 15.25.1.2.1.8-.5.8-9.1-.5-14.34-.8-23.13-.5-.8 1.52-1.24 3.4-1.44 7.14.96-.94 3.28-1.37 6.21-1.55 3.25-.2 7.92 1.4 14.32 1.55 6.4.15 8.58.84 10.18-1.55.51-1.6.2-4.3-1.76-8.68-.77-1.74-4.16-6.09-4.79-7.92-1-2.93 2.53-5.85 7.68-8.18-1.41-4.85-12.12-7.67-12.93-11.61-.2-2.93 0-8.59 1.62-10.4 1.21-1.42 4.44-6.87 6.06-11.92-.1-.81-3.23-1.52-3.23-1.52z"
      />
      <g transform="translate(112.08 112.94)">
        <mask id="noAccess_b" fill="#fff">
          <use xlinkHref="#noAccess_a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#noAccess_a" />
        <path
          fill="#F1F6FF"
          d="M15.94 17.78a7 7 0 01-3.2-.4c-1.48-.47-4.66-1.83-4.66-1.42 0 .41 3.24 4.43 3.95 4.61.7.19 3.9-2.32 3.9-2.79z"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#4B546B"
        fillRule="nonzero"
        d="M126.13 114.36c2.83-.5 3.34 1.31 2.83 2.73-.6 1.51-1.62 2.02-3.43 2.32-1.11.2-2.02-.7-3.34.2-1.3.91-1.51 3.64-1.92 3.33-.4-.4-1.4-2.32-2.42-1.61-1.21.9-.7 4.14.4 4.14s0 2.83-.4 3.33-2.52-.2-2.52-.2-4.75-3.43-3.74-9.4c.5-2.62 3.63-8.07 8.99-6.66 2.93.7 3.84 2.12 5.55 1.82z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M121.59 139.7c4.74 3.54 8.28 12.13 10.8 15.76 3.94 3.74 10.3 8.49 15.86 12.33.4.2 5.86-1.11 5.96-.3.1.6-2.83 1.6-2.32 1.81 2.72 1.11 6.26 2.73 5.95 3.43-.1.2-3.93-.7-4.14-.4-.1.2 3.03 1.62 2.93 1.72-1.1 1.31-3.53 2.12-4.74 2.12-2.33-.7-3.54-1.21-6.97-3.84-7.27-3.23-18.59-9.6-19.3-10.6-2.21-1.92-9.39-12.22-11.91-18.38-1.72-4.55 4.75-5.96 7.88-3.64z"
      />
      <path
        fill="#5081FF"
        fillRule="nonzero"
        d="M141.59 168.29c-3.54-4.64-24.44 4.04-25.96 2.12-.3-.4 1.11-4.04 1.92-5.25a54.72 54.72 0 004.54-7.98s-.7-1-1.31-2.12c2.42-1.72 7.88-5.76 8.28-7.68a25.76 25.76 0 00-4.85-7.07c-.5-2.22-.7-3.63-2.12-5.35l-3.13-1.61s-1.82-4.14-8.28.4c-8.68 6.97-11.71 21-15.45 29.7A80.88 80.88 0 0092.6 170s-4.14 7.87 4.65 15.15c1.1.9 4.34 2.32 6.87 2.72 6.66 1.52 18.38-3.94 24.64-5.05.6-.1 1.1.3 1.21.91.91 6.87 4.34 21.62 6.36 25.25 5.15 2.22 11.52-1.31 11.52-1.31s-3.34-35.45-6.26-39.39z"
      />
      <path
        fill="#426FE2"
        fillRule="nonzero"
        d="M122.8 135.37c-3.43-4.14-7.98-5.46-7.98-5.46l-1.92 2.63s6.16 1.92 10.3 4.75c0-.71-.1-1.42-.4-1.92zm-9.19 3.23c-3.13 7.67 4.44 15.35 5.25 16.66.7 1.32 1.92-.2 1.92-.2l1.31 2.12s-3.23 6.06-3.94 6.97a37.23 37.23 0 00-1.81 3.33c-.3.81-1.01 2.53-.81 2.93.2.6-8.99 3.13-10 2.43-1.01-.71 4.85-3.54 6.77-6.16 2.22-3.24 1.81-7.48.1-12.93-1.82-5.35-2.02-13.13 1.2-15.15z"
      />
    </g>
  </svg>
)

export default NoAccess
