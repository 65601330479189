import React, {Fragment, useContext} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import AppContext from 'containers/App/context'
import Page from 'components/Page'
import SideNav from 'components/SideNav'
import RecordsUploaderList from 'containers/RecordsUploaderList'

const NAV_ITEMS: {
  path: string
  name: string
  isActive?: (pathname: string, search: string) => boolean | null | undefined
  items?: {
    path: string
    name: string
    isActive?: (pathname: string, search: string) => boolean | null | undefined
  }[]
}[] = [
  {
    path: 'setting/account',
    name: 'Управление аккаунтом'
  },
  {
    path: 'users/list',
    name: 'Пользователи',
    isActive: (pathname, search) =>
      (pathname.includes('/users/view/') && !search.includes('method=')) || null
  },
  {
    path: 'users/groups',
    name: 'Группы пользователей',
    isActive: (pathname, search) =>
      (pathname.endsWith('/users/view') && search.includes('method=')) || null
  },
  {
    path: 'settings/additional',
    name: 'Дополнительные данные о видео',
    items: [
      {
        path: 'setting/metadata',
        name: 'Метаданные',
        isActive: (pathname) => pathname.endsWith('/setting/metaedit') || null
      },
      {
        path: 'setting/photobanks',
        name: 'Фотобанки'
      }
    ]
  },
  {
    path: 'setting/player',
    name: 'Шаблоны плеера',
    isActive: (pathname) => pathname.endsWith('/setting/playeredit') || null
  },
  {
    path: 'setting/site',
    name: 'Шаблоны доступа на сайтах',
    isActive: (pathname) => pathname.endsWith('/setting/siteedit') || null
  },
  {
    path: 'setting/country',
    name: 'Шаблоны доступа в странах',
    isActive: (pathname) => pathname.endsWith('/setting/countryedit') || null
  },
  {
    path: 'setting/ads',
    name: 'Шаблоны рекламы'
  },
  {
    path: 'setting/copyright',
    name: 'Правообладатели'
  },
  {
    path: 'setting/uploader',
    name: 'Контроль загрузки видео'
  }
]

const Settings: React.FC = () => {
  const {getPath, getAccountPermissions} = useContext(AppContext)
  const {availableSections} = getAccountPermissions()
  const {pathname, search} = useLocation()
  const hasPhotobanks = availableSections.includes('PHOTOBANK')

  return (
    <Page
      aside={
        <Fragment>
          <SideNav>
            {NAV_ITEMS.map(({path, name, items, isActive}) =>
              Array.isArray(items)
                ? [
                    name,
                    ...items
                      .filter((item) => {
                        return hasPhotobanks
                          ? item
                          : item.path !== 'setting/photobanks'
                      })
                      .map(({name, path, isActive}) => ({
                        children: name,
                        to: getPath(path),
                        isActive: isActive?.(pathname, search) ?? undefined
                      }))
                  ]
                : {
                    children: name,
                    to: getPath(path),
                    isActive: isActive?.(pathname, search) ?? undefined
                  }
            )}
          </SideNav>
          <RecordsUploaderList />
        </Fragment>
      }>
      <Helmet>
        <title>Настройки</title>
      </Helmet>

      <Outlet />
    </Page>
  )
}

export default Settings
