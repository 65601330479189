// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nLU18 {\n  width: 302px;\n  min-width: 300px;\n}\n\n.s0l2J {\n  color: var(--rui-ramblerBlue);\n  cursor: pointer;\n}\n\n/* TODO: удалить футер после редизайна админки */\n\n.redesigned-body .quQA4 {\n    display: none;\n  }\n", "",{"version":3,"sources":["webpack://./src/containers/App/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;AACjB;;AAEA,gDAAgD;;AAE9C;IACE,aAAa;EACf","sourcesContent":[".confirmation {\n  width: 302px;\n  min-width: 300px;\n}\n\n.logout {\n  color: var(--rui-ramblerBlue);\n  cursor: pointer;\n}\n\n/* TODO: удалить футер после редизайна админки */\n.footer {\n  :global(.redesigned-body) & {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmation": "nLU18",
	"logout": "s0l2J",
	"footer": "quQA4"
};
export default ___CSS_LOADER_EXPORT___;
