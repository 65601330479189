import React, {Children, isValidElement} from 'react'
import classnames from 'classnames'
import styles from './styles.css'

type ChildrenArray<T> = T | T[]

type Props = {
  className?: string
  itemClassName?: string
  children: ChildrenArray<React.ReactElement | false | null>
}

const ControlsList: React.FC<Props> = ({
  className,
  itemClassName,
  children,
  ...props
}) => {
  const resultItemClassName = classnames(itemClassName, styles.item)

  return (
    <ul className={classnames(className, styles.list)} {...props}>
      {Children.map(
        children,
        (item) =>
          item &&
          isValidElement(item) && (
            <li className={resultItemClassName} key={item.key}>
              {item}
            </li>
          )
      )}
    </ul>
  )
}

export default ControlsList
