import React from 'react'
import classnames from 'classnames'
import {Scrollbar} from 'react-scrollbars-custom'
import styles from './styles.css'

const STYLES = {
  position: undefined,
  top: undefined,
  bottom: undefined,
  display: undefined,
  width: undefined,
  height: undefined,
  marginLeft: undefined,
  marginRight: undefined,
  paddingLeft: undefined,
  paddingRight: undefined,
  borderRadius: undefined,
  background: undefined
}

type Props = Pick<
  React.ComponentProps<typeof Scrollbar>,
  'className' | 'style' | 'children' | 'onUpdate'
> & {
  forwardRef?: React.RefObject<typeof Scrollbar>
}

const CustomScrollbar: React.FC<Props> = ({
  className,
  style,
  children,
  forwardRef,
  onUpdate
}) => (
  <Scrollbar
    onUpdate={onUpdate}
    style={style}
    ref={forwardRef}
    translateContentSizeYToHolder
    renderer={({elementRef, style, ...props}) => (
      <div
        {...props}
        className={classnames(className, styles.root)}
        ref={elementRef}
        style={{...style, ...STYLES}}
      />
    )}
    contentProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.content}
          style={{...style, ...STYLES}}
        />
      )
    }}
    wrapperProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.scrollbar}
          style={{...style, ...STYLES}}
        />
      )
    }}
    scrollerProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.inner}
          style={{...style, ...STYLES}}
        />
      )
    }}
    trackYProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.track}
          style={{...style, ...STYLES}}
        />
      )
    }}
    thumbYProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.thumb}
          style={{...style, ...STYLES, top: style?.top}}
        />
      )
    }}>
    {children}
  </Scrollbar>
)

export default CustomScrollbar
