import React, {Suspense, lazy} from 'react'
import Spinner from 'rambler-ui/Spinner'

const SettingsRoutes = lazy(
  () =>
    import(
      /* webpackChunkName: "Settings" */
      './SettingsRoutes'
    )
)

const LazySettingsRoutes: React.FC = () => (
  <Suspense fallback={<Spinner />}>
    <SettingsRoutes />
  </Suspense>
)

export default LazySettingsRoutes
