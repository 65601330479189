import React, {memo, useContext} from 'react'
import {Link} from 'react-router-dom'
import classnames from 'classnames'
import VolumeMuteIcon from 'rambler-ui/icons/forms/VolumeMuteIcon'
import {fractionNumberFormat} from 'utils/numbers'
import RedoIcon from 'components/icons/Redo'
import TrashIcon from 'components/icons/Trash'
import CloseIcon from 'components/icons/Close'
import ProgressBar from 'components/ProgressBar'
import type {Item} from 'containers/UploadRecordsProvider/context'
import AppContext from 'containers/App/context'
import styles from './styles.css'

type Props = {
  itemId: number
  item: Item
  abortUploading: (itemId: number) => any
  retryUploading: (itemId: number) => any
  deleteItem: (itemId: number, deleteRecord?: boolean) => any
}

const RecordsUploaderItem: React.FC<Props> = ({
  itemId,
  item: {
    status,
    uploaderStatus,
    uploadId,
    file,
    soundDisabled,
    progress,
    recordId,
    replacedRecordId,
    accountCode,
    accountName
  },
  abortUploading,
  retryUploading,
  deleteItem
}: Props) => {
  const currRecordId = replacedRecordId ?? recordId
  const {getPath} = useContext(AppContext)

  const started = status === 'IN_PROGRESS'
  const completed = status === 'COMPLETED'
  const failed = status === 'FAILED'
  const aborted = status === 'ABORTED'
  const isProcessingSuccessed = uploaderStatus === 'READY'
  const isProcessingFailed = uploaderStatus === 'ERROR'

  let statusText

  if (failed) {
    statusText = 'Ошибка загрузки'
  } else if (aborted) {
    statusText = 'Загрузка прервана'
  } else if (completed) {
    if (isProcessingSuccessed) statusText = 'Обработка завершена'
    else if (isProcessingFailed) statusText = 'Ошибка обработки'
    else statusText = 'В обработке'
  } else if (started) {
    statusText = `${fractionNumberFormat(
      (file.size * progress) / 1e6
    )} / ${fractionNumberFormat(file.size / 1e6)} MB`
  } else {
    statusText = 'В очереди'
  }

  let retryControl
  let abortControl

  if (failed || aborted) {
    retryControl = (
      <RedoIcon
        className={classnames(styles.icon, styles.retry)}
        onClick={() => retryUploading(itemId)}
        role="button"
      />
    )
  }

  if (completed || (!started && (replacedRecordId || !recordId))) {
    abortControl = (
      <CloseIcon
        className={styles.icon}
        onClick={() => deleteItem(itemId)}
        role="button"
      />
    )
  } else if (started) {
    if (uploadId)
      abortControl = (
        <CloseIcon
          className={styles.icon}
          onClick={() => abortUploading(itemId)}
          role="button"
        />
      )
  } else {
    abortControl = (
      <TrashIcon
        className={classnames(styles.icon, styles.delete)}
        onClick={() => deleteItem(itemId, true)}
        role="button"
      />
    )
  }

  return (
    <li className={styles.item}>
      {!!soundDisabled && (
        <VolumeMuteIcon className={styles.soundIcon} size={null} color={null} />
      )}
      <div className={styles.name}>
        {currRecordId ? (
          <Link to={getPath(`records/${currRecordId}/attributes`, accountCode)}>
            {file.name}
          </Link>
        ) : (
          file.name
        )}
      </div>

      {retryControl || null}
      {abortControl || null}

      <ProgressBar
        className={styles.progressBar}
        statusClassName={styles.progressStatus}
        max={1}
        value={progress}
        completed={completed}
        failed={failed || aborted}
        active={started}>
        <div className={styles.statusText}>{statusText}</div>
        <div className={styles.account}>{accountName}</div>
      </ProgressBar>
    </li>
  )
}

export default memo<Props>(RecordsUploaderItem)
