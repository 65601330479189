import React, {useContext, useState, useCallback} from 'react'
import classnames from 'classnames'
import {Link, useLocation} from 'react-router-dom'
import ArrowDownIcon from 'components/icons/redesigned/arrowDown.svg'
import More2Icon from 'components/icons/redesigned/more2.svg'
import {DropdownWrapper, Dropdown} from 'components/rc/dropdown'
import MenuItem from 'components/Menu/Item'
import IconText from 'components/IconText'
import ContentButton from 'components/ContentButton'
import AppContext from 'containers/App/context'
import type {Route} from './index'
import styles from './styles.css'

type Props = Route

const NavDropdown: React.FC<Props> = ({
  label,
  options,
  account,
  matchSections,
  beta,
  id
}) => {
  const [isOpen, setState] = useState(false)
  const close = useCallback(() => setState(false), [])
  const open = useCallback(() => setState(true), [])

  const {getPath} = useContext(AppContext)
  const {pathname} = useLocation()
  const matcher = (item: string): boolean =>
    pathname.startsWith(account ? getPath(item) : `/${item}`)

  return (
    <DropdownWrapper className={styles.dropdownWrapper}>
      <IconText
        className={classnames(
          styles.navAnchor,
          matchSections?.some(matcher) && styles.activeItem
        )}
        container={<ContentButton />}
        iconComponent={id === 'more' ? More2Icon : ArrowDownIcon}
        onClick={isOpen ? close : open}
        data-badge={beta?.some(matcher) ? 'beta' : undefined}
        rightIcon>
        {label}
      </IconText>
      <Dropdown
        className={styles.dropdown}
        minWidth={200}
        isOpen={isOpen}
        onClose={close}
        display="bright">
        {options?.map(({label, route, account, matchSections}) => (
          <MenuItem
            key={route}
            container={
              <Link to={account ? getPath(route as string) : `/${route}`} />
            }
            onClick={close}
            highlight={matchSections?.some((item) =>
              pathname.startsWith(account ? getPath(item) : `/${item}`)
            )}
            bold>
            {label}
          </MenuItem>
        ))}
      </Dropdown>
    </DropdownWrapper>
  )
}

export default NavDropdown
