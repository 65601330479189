// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YPTM9 {\n  width: 450px;\n  padding: 20px;\n}\n\n  .YPTM9 footer {\n    justify-content: center;\n    margin: 20px 0 0;\n  }\n\n.gEQBj {\n  display: block;\n  margin: 0 auto 20px;\n}\n\n.PVDsb {\n  margin: 15px 0;\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 25px;\n  text-align: center;\n}\n\n.Xc_gv {\n  margin: 15px 0;\n  text-align: center;\n}\n\n.Xc_gv a {\n    color: var(--rui-purpleBlue);\n    transition: color .2s;\n  }\n\n.Xc_gv a:hover {\n      color: var(--rui-ramblerBlue);\n    }\n\n.npObY {\n  flex: none;\n  width: auto;\n  margin: 0 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/WelcomePopup/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AAMf;;EAJE;IACE,uBAAuB;IACvB,gBAAgB;EAClB;;AAGF;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AAUpB;;AARE;IACE,4BAA4B;IAC5B,qBAAqB;EAKvB;;AAHE;MACE,6BAA6B;IAC/B;;AAIJ;EACE,UAAU;EACV,WAAW;EACX,cAAc;AAChB","sourcesContent":[".root {\n  width: 450px;\n  padding: 20px;\n\n  footer {\n    justify-content: center;\n    margin: 20px 0 0;\n  }\n}\n\n.image {\n  display: block;\n  margin: 0 auto 20px;\n}\n\n.heading {\n  margin: 15px 0;\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 25px;\n  text-align: center;\n}\n\n.message {\n  margin: 15px 0;\n  text-align: center;\n\n  a {\n    color: var(--rui-purpleBlue);\n    transition: color .2s;\n\n    &:hover {\n      color: var(--rui-ramblerBlue);\n    }\n  }\n}\n\n.button {\n  flex: none;\n  width: auto;\n  margin: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "YPTM9",
	"image": "gEQBj",
	"heading": "PVDsb",
	"message": "Xc_gv",
	"button": "npObY"
};
export default ___CSS_LOADER_EXPORT___;
