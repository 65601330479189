// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lQoJ7 {\n  --section-heading-top-gap: 40px;\n  --section-heading-bottom-gap: 20px;\n  --section-heading-font-size: 17px;\n  --section-heading-font-weight: 500;\n\n  margin: var(--section-heading-top-gap) 0 var(--section-heading-bottom-gap);\n  font-size: var(--section-heading-font-size);\n  font-weight: var(--section-heading-font-weight);\n  line-height: 25px;\n}\n\n  .redesigned .lQoJ7 {\n    --section-heading-top-gap: 20px;\n    --section-heading-bottom-gap: 20px;\n    --section-heading-font-size: 18px;\n    --section-heading-font-weight: 800;\n  }\n", "",{"version":3,"sources":["webpack://./src/components/SectionHeading/styles.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kCAAkC;EAClC,iCAAiC;EACjC,kCAAkC;;EAElC,0EAA0E;EAC1E,2CAA2C;EAC3C,+CAA+C;EAC/C,iBAAiB;AAQnB;;EANE;IACE,+BAA+B;IAC/B,kCAAkC;IAClC,iCAAiC;IACjC,kCAAkC;EACpC","sourcesContent":[".root {\n  --section-heading-top-gap: 40px;\n  --section-heading-bottom-gap: 20px;\n  --section-heading-font-size: 17px;\n  --section-heading-font-weight: 500;\n\n  margin: var(--section-heading-top-gap) 0 var(--section-heading-bottom-gap);\n  font-size: var(--section-heading-font-size);\n  font-weight: var(--section-heading-font-weight);\n  line-height: 25px;\n\n  :global(.redesigned) & {\n    --section-heading-top-gap: 20px;\n    --section-heading-bottom-gap: 20px;\n    --section-heading-font-size: 18px;\n    --section-heading-font-weight: 800;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "lQoJ7"
};
export default ___CSS_LOADER_EXPORT___;
