import React, {Suspense, lazy} from 'react'
import PageFallback from 'components/Page/Fallback'

const Stat = lazy(
  () =>
    import(
      /* webpackChunkName: "Stat" */
      './'
    )
)

const LazyStat: React.FC = () => (
  <Suspense fallback={<PageFallback />}>
    <Stat />
  </Suspense>
)

export default LazyStat
