import React, {Fragment, Children, cloneElement, isValidElement} from 'react'
import classnames from 'classnames'
import Button from 'components/rc/button'
import styles from './styles.css'

type FooterProps = React.HTMLProps<HTMLDivElement> & {
  isSticky?: boolean
  forwardRef?: React.RefObject<HTMLElement>
  innerRef?: React.RefObject<HTMLDivElement>
}

export const PageFooter: React.FC<FooterProps> = ({
  className,
  isSticky,
  children,
  forwardRef,
  innerRef,
  ...props
}) => (
  <footer
    {...props}
    className={classnames(
      className,
      styles.footer,
      isSticky && styles.isSticky,
      'redesigned'
    )}
    ref={forwardRef}>
    <div className={styles.footerInner} ref={innerRef}>
      {children}
    </div>
  </footer>
)

type ChildrenArray<T> = T | T[]

type FooterButtonsProps = {
  children: ChildrenArray<React.ReactElement<typeof Button> | null | false>
}

export const PageFooterButtons: React.FC<FooterButtonsProps> = (props) => (
  <Fragment>
    {Children.map(
      props.children,
      (button, index) =>
        isValidElement(button) &&
        cloneElement(button, {
          key: index,
          className: classnames(styles.button, button.props.className)
        })
    )}
  </Fragment>
)
