import React, {Suspense, lazy} from 'react'
import PageFallback from 'components/Page/Fallback'

const CopyrightHoldersDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "CopyrightHoldersDashboard" */
      './'
    )
)

const LazyCopyrightHoldersDashboard: React.FC = () => (
  <Suspense fallback={<PageFallback />}>
    <CopyrightHoldersDashboard />
  </Suspense>
)

export default LazyCopyrightHoldersDashboard
