import {environment, isDevelopment} from 'utils/env'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'

const SENTRY_DSN = process.env.SENTRY_DSN

export function activateSentry(): void {
  if (isDevelopment) {
    console.log('activateSentry') // eslint-disable-line no-console

    return
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment,
    release: __TAG__,
    sampleRate: 1,
    tracesSampler: 1
  })
  Sentry.configureScope((scope) => {
    scope.setTag('git_commit', __COMMIT__)
  })
}

export const captureException = (
  error: Error,
  extra?: Record<string, any> | null | undefined
): void => {
  if (isDevelopment) {
    console.log('captureException', error, extra) // eslint-disable-line no-console

    return
  }

  Sentry.withScope((scope) => {
    if ('code' in error) scope.setExtra('code', error.code)
    if (extra != null)
      Object.keys(extra).forEach((key) => scope.setExtra(key, extra[key]))
    Sentry.captureException(error)
  })
}
