import React, {useState, useCallback} from 'react'
import classnames from 'classnames'
import HelpIcon from 'components/icons/redesigned/help2.svg'
import MailIcon from 'components/icons/redesigned/mail.svg'
import TelegramIcon from 'components/icons/redesigned/telegram.svg'
import {DropdownWrapper, Dropdown} from 'components/rc/dropdown'
import IconText from 'components/IconText'
import ContentButton from 'components/ContentButton'
import styles from './styles.css'

const TG_CHANNEL_URL = process.env.TG_CHANNEL_URL
const SUPPORT_EMAIL = process.env.SUPPORT_EMAIL

const HelpDropdown: React.FC = () => {
  const [isOpen, setState] = useState(false)
  const close = useCallback(() => setState(false), [])
  const open = useCallback(() => setState(true), [])

  return (
    <DropdownWrapper className={styles.dropdownWrapper}>
      <IconText
        className={styles.navAnchor}
        container={<ContentButton />}
        iconComponent={HelpIcon}
        onClick={isOpen ? close : open}>
        Помощь
      </IconText>
      <Dropdown
        className={classnames(styles.infoDropdown, styles.dropdown)}
        width="fit-content"
        minWidth={290}
        maxWidth="initial"
        isOpen={isOpen}
        onClose={close}
        display="bright">
        <h5 className={styles.dropdownHeading}>Нужна помощь? Мы на связи:</h5>
        {(
          [
            [SUPPORT_EMAIL, `mailto:${SUPPORT_EMAIL}`, MailIcon],
            ['Новости видеоплатформы', TG_CHANNEL_URL, TelegramIcon]
          ] as const
        ).map(([label, href, Icon], index) => (
          <a
            className={styles.infoDropdownItem}
            key={index}
            href={href}
            target="_blank"
            rel="noreferrer">
            <Icon />
            {label}
          </a>
        ))}
      </Dropdown>
    </DropdownWrapper>
  )
}

export default HelpDropdown
