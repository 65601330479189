import {isObject} from 'utils/checkType'
const LOCAL_STORAGE_KEY = 'vpadmin:userVisits'

const EMPTY_OBJ: Record<never, never> = {}

const getUserVisitis = (): Record<number, true> => {
  try {
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '')

    return isObject(data) ? data : EMPTY_OBJ
  } catch (err: any) {
    return EMPTY_OBJ
  }
}

export const hasUserVisited = (userId: number): boolean => {
  try {
    return getUserVisitis().hasOwnProperty(userId)
  } catch (err: any) {
    return false
  }
}

export const saveUserVisit = (userId: number): void => {
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({
      ...getUserVisitis(),
      [userId]: true
    })
  )
}
