import React, {Suspense, lazy} from 'react'
import PageFallback from 'components/Page/Redesigned'
import {Props} from './'

const Records = lazy(
  () =>
    import(
      /* webpackChunkName: "Records" */
      './'
    )
)

const LazyRecords: React.FC<Props> = (props) => (
  <Suspense fallback={<PageFallback />}>
    <Records {...props} />
  </Suspense>
)

export default LazyRecords
