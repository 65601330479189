import React from 'react'
import classnames from 'classnames'
import styles from './styles.css'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  forwardRef?: React.RefObject<HTMLButtonElement>
}

const ContentButton: React.FC<Props> = ({forwardRef, ...props}) => {
  return (
    <button
      type="button"
      {...props}
      className={classnames(props.className, styles.button)}
      ref={forwardRef}>
      {props.children}
    </button>
  )
}

export default ContentButton
