import React from 'react'
import SvgIcon from 'rambler-ui/SvgIcon'

const TrashIcon: React.FC<
  Omit<React.ComponentProps<typeof SvgIcon>, 'children' | 'viewBox'>
> = (props) => (
  <SvgIcon color={null} size={null} {...props} viewBox="0 0 15 15">
    <path d="M0 0v15V0zm15 15V0v15zM9.5 1V.5A.5.5 0 0 0 9 0H6a.5.5 0 0 0-.5.5V1h-4a.5.5 0 0 0-.5.5v.2a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-.2a.5.5 0 0 0-.5-.5h-4zm2.3 2.9v9.9H3.2V3.9a.5.5 0 0 0-.5-.5h-.2a.5.5 0 0 0-.5.5V14a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V3.9a.5.5 0 0 0-.5-.5h-.2a.5.5 0 0 0-.5.5z" />
  </SvgIcon>
)

export default TrashIcon
