type Environment = 'production' | 'stage' | 'dev'

export const cdnOrigin: string = process.env.CDN_ORIGIN
export const apiOrigin: string = process.env.API_ORIGIN
export const authOrigin: string = process.env.AUTH_ORIGIN
export const oldHost: string = process.env.OLD_HOST

export const environment: Environment = apiOrigin.includes('vp-stage')
  ? 'stage'
  : apiOrigin.includes('vp-dev')
  ? 'dev'
  : 'production'

export const isDevelopment: boolean = __NODE_ENV__ === 'development'

export const OLD_PLAYER_ACCOUNTS: Map<string, true> = new Map(
  ['afisha', 'afisharest', 'ramblerkassa', 'lj'].map((i) => [i, true])
)

export const getInternalHref = (path: string): string =>
  `${process.env.CDN_PREFIX}${path}`
