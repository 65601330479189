import {isDevelopment, environment} from 'utils/env'

const YANDEX_METRIKA_ID = process.env.YANDEX_METRIKA_ID

const isDebug =
  isDevelopment || environment === 'stage' || environment === 'dev'

const loadYandexMetrika = (): void => {
  // eslint-disable-next-line padding-line-between-statements
  ;(function (m, e, t, r, i, k, a) {
    m[i] =
      m[i] ||
      function (...args) {
        ;(m[i].a = m[i].a || []).push(args)
      }

    m[i].l = 1 * new Date()
    // eslint-disable-next-line sonar/no-parameter-reassignment
    k = e.createElement(t)
    // eslint-disable-next-line sonar/no-parameter-reassignment
    a = e.getElementsByTagName(t)[0]
    k.async = 1
    k.src = r
    a.parentNode.insertBefore(k, a)
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

  ym(YANDEX_METRIKA_ID, 'init', {
    defer: true,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    triggerEvent: true
  })
}

export const activateMetrics = (): void => {
  if (isDebug) {
    console.log('activate metrics') // eslint-disable-line no-console

    return
  }

  loadYandexMetrika()
}

export const hit = async (pathname: string): Promise<void> => {
  if (isDebug) {
    console.log('metrics hit', pathname) // eslint-disable-line no-console

    return
  }

  ym(YANDEX_METRIKA_ID, 'hit', pathname)
}
