import React, {cloneElement, useState, Fragment} from 'react'
import {NavLink} from 'react-router-dom'
import classnames from 'classnames'
import ButtonWithArrow from 'components/ButtonWithArrow'
import styles from './styles.css'

type NavLinkProps = Omit<
  React.ComponentProps<typeof NavLink>,
  'to' | 'children' | 'className'
> & {
  to: string
  children: string
  className?: string
  isActive?: boolean
}

type ItemProps = NavLinkProps & {
  icon?: React.ReactElement
  count?: number
}

type SubMenuProps = {
  label: string
  children: React.ReactElement<typeof NavLink>[]
}

const SubMenu: React.FC<SubMenuProps> = ({label, children}) => {
  const [isExpanded, toggleExpanded] = useState(false)

  return (
    <Fragment>
      <ButtonWithArrow
        className={classnames(styles.item, styles.subLabel)}
        onClick={() => toggleExpanded(!isExpanded)}
        invertArrow={isExpanded}>
        {label}
      </ButtonWithArrow>
      {isExpanded && children}
    </Fragment>
  )
}

type MenuProps = {
  className?: string
  children: (ItemProps | [string, ...NavLinkProps[]])[]
}

const SideNav: React.FC<MenuProps> = ({className, children}) => (
  <nav className={classnames(className, styles.root)}>
    {children.map((item) => {
      if (Array.isArray(item)) {
        const [label, ...items] = item
        const [firstItem] = items

        return (
          <SubMenu key={firstItem.to} label={label}>
            {items.map(({isActive: isActiveFromProps, ...props}) => (
              <NavLink
                caseSensitive
                key={props.to}
                {...props}
                className={({isActive}) =>
                  classnames(
                    props.className,
                    styles.item,
                    styles.subItem,
                    (isActiveFromProps ?? isActive) && styles.activeItem
                  )
                }
              />
            ))}
          </SubMenu>
        )
      }

      const {
        icon,
        count,
        children,
        isActive: isActiveFromProps,
        ...props
      } = item

      return (
        <NavLink
          caseSensitive
          key={props.to}
          {...props}
          className={({isActive}) =>
            classnames(
              props.className,
              styles.item,
              (isActiveFromProps ?? isActive) && styles.activeItem
            )
          }>
          {icon != null &&
            cloneElement(icon, {
              className: classnames(styles.icon, icon.props.className)
            })}

          <span className={styles.label}>{children}</span>

          {count != null && <span className={styles.count}>{count}</span>}
        </NavLink>
      )
    })}
  </nav>
)

export default SideNav
