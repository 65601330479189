import React from 'react'
import getDisplayName from 'rambler-ui/utils/get-display-name'
import Context, {
  type ContextType
} from 'containers/UploadRecordsProvider/context'

export type WithUploadRecordsProps = {
  uploadRecords: ContextType
}

export default function <Config>(
  Target: React.ComponentType<Config>
): React.FC<Omit<Config, keyof WithUploadRecordsProps>> {
  const WithUploadRecords: React.FC<
    Omit<Config, keyof WithUploadRecordsProps>
  > = (props) => (
    <Context.Consumer>
      {(uploadRecordsContext) => (
        <Target {...(props as Config)} uploadRecords={uploadRecordsContext} />
      )}
    </Context.Consumer>
  )

  WithUploadRecords.displayName = `withUploadRecords(${getDisplayName(Target)})`

  return WithUploadRecords
}
